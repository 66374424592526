<template lang="html">
	<div class="vista" id="cotizacion">
		<div class="tab-content" id="myTabContent">
        <div v-if="this.SmsEnvio==false">
			<div class="" id="home" v-if="mostrarDatosCotizacion">

				<!--<vx-card title="" class="nueva">-->

          <vs-prompt title="Aviso"  class="export-options prompt-modal " :active="!completeInfoCirculo" @close="MostrarMensajeCirculo()" style="text-align:center;">
              Tu empresa no cuenta con las consultas disponibles o no tiene la facultad para poder realizar consultas de Circulo de Credito.



              <div class="row w-full"  style="display:flex; justify-content:center;">
                <div class="col-md-3">
                  <button class="btn btn-second" @click="MostrarMensajeCirculo()">Aceptar</button>
                </div>
              </div>
		   </vs-prompt>

         <!-- <form>-->
            <div class="row w-full">
              <div class="col-md-8">
                <label class="span-placeholder" for="destinos">Destino</label>
                <select name="cars" id="destinos" v-model="caso.destino" class="vs-input" @click="factorySettings()" @change="mostrarProgramas()">
                  <option :value='tipo.Id' v-for="tipo in destinos"  >{{tipo.Nombre}}</option>
                </select>
              </div>
            </div>
            <div class="row w-full">
              <div class="col-md-8" v-if="this.ProgramaDefaul==true" ><!--v-if="caso.destino == 1 || caso.destino == 5 || caso.destino == 2 || caso.destino == 20"-->
                <label class="span-placeholder" for="Programas">Programas</label>
                <select name="cars" id="Programas" v-model="caso.programa" class="vs-input">
                  <option :value='tipo.Id' v-for="tipo in programas"  >{{tipo.Nombre}}</option>
                </select>
              </div>
            </div>  
            <AdquisicionCofi :programa="caso.programa" :otro="otro" v-if="caso.destino == 1 && (caso.programa == 4 || caso.programa == 9 )" v-on:updateValores="update($event)"/>
            <Adquisicion :otro="otro" :IngresoSolN="IngresoSol" :destino="caso.destino"  v-if="(caso.destino == 1 || caso.destino == 24 || caso.destino == 21 || caso.destino == 3 || caso.destino == 27 || caso.destino == 15 || caso.destino == 8 || caso.destino == 10 || caso.destino == 32 || caso.destino == 37) && (caso.programa==1 || caso.programa==6)" v-on:updateValores="update($event)" v-on:buscaBancos="cambiaBancos($event)" />
            <Mejora :IngresoSolN="IngresoSol" v-if="caso.destino == 19 || caso.destino == 25 || caso.destino == 22 || caso.destino == 33 || caso.destino == 34" v-on:updateValores="update($event)" v-on:buscaBancos="cambiaBancos($event)"/>
            <TerrenoProgramas :programa="caso.programa" :otro="otro"  :IngresoSolN="IngresoSol"  v-if="(caso.destino == 5 || caso.destino == 20 || caso.destino == 2)&& (caso.programa == 4 || caso.programa == 9 )" v-on:updateValores="update($event)"/>
            <Terreno :otro="otro" :IngresoSolN="IngresoSol" :destino="caso.destino"  v-if="(caso.destino == 5 || caso.destino == 20 || caso.destino == 2) && (caso.programa==1 || caso.programa==6) " v-on:updateValores="update($event)" v-on:buscaBancos="cambiaBancos($event)"/>
            
            <!-- <div class="row w-full">
              <div class="col-md-4">
                <span class="text-sm" for="gastosNotariales">Gastos notariales</span>
                <select name="gastosNotariales" id="gastosNotariales" v-model="caso.gastosNotarial" class="v-select vs--single  vs-input--input">
                  <option :value='tipo.value' v-for="tipo in gastosNotariales"  :key="">{{tipo.label}}</option>
                </select>
              </div>
             
             </div>
            <div class="row w-full">
              <div class="col-md-8">
                <label class="span-placeholder">Bancos</label>
                <v-select v-model="caso.bancos" multiple :closeOnSelect="false" :options="bancos" :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
              </div>

            </div>
           <div class="row w-full  ">
              <div class=" col-md-8 align-blue">
              <vs-button class="btn-blue" @click.prevent="seleccionarTodos()" >Seleccionar todo</vs-button>
              
              <vs-button class="btn-blue" @click.prevent="limpiarBancos()" >Limpiar Selección</vs-button>
              </div>
        
            </div>
            <div class="row w-full">
              <div class="col-md-4">
                <label class="span-placeholder" for="estatus">Perfil de cliente</label>
                <select name="cars" id="tasas" v-model="caso.tasa" class="vs-input">
                  <option :value='tipo.Id' v-for="tipo in tasas"  >{{tipo.Nombre}}</option>
                </select>
              </div>
             <div class="col-md-4">
                <span class="span-placeholder" for="estados">Estado</span>
                <select name="cars" id="estados" v-model="caso.estado" class="vs-input" @change="mostrarProgramas()">
                  <option :value='tipo.Id' v-for="tipo in estados"  >{{tipo.Nombre}}</option>
                </select>
              </div>
            </div>-->
            <br>

        <div class="row w-full">
					<div class="col-md-4"></div>
					<div class="col-md-2">
            <button class="btn btn-empty" @click="RegresarDomicilio()"  ><h5>Regresar</h5></button>
					</div>
					<div class="col-md-2">
						 <button class="btn btn-second" @click.prevent="registrarCaso()":disabled="!validateForm">Precalificar</button>
					</div>
          <div class="col-md-2"></div>
				</div>

            
           
          <!--</vx-card  >-->
        <!--</form>-->
     
  </div>
  <div class="opciones" id="profile" v-if="mostrarOpciones">
    <div class="excel">
          <button  class="btn btn-exportar mt-4" @click="regresarAcotizacion()" >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.334 11.675L12.148 14.861L15.334 18.047" stroke="#26558F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M24.892 14.861L12.234 14.861" stroke="#26558F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path opacity="0.4" d="M14.9349 24.892C9.43494 24.892 4.97794 21.158 4.97794 14.935C4.97794 8.71201 9.43493 4.97801 14.9349 4.97801" stroke="#26558F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg><strong>Regresar</strong>
          </button>
      </div>
    <vx-card title="" class="" v-if="items.length">
          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
            <h4 class="titulo ">Opciones de crédito</h4>
            <button  class="btn btn-success" @click="mostrarFormSolicitante" >Guardar</button>
          </div>
          <div class="">
            <p class="text-grey mb-0" id="title" v-if="caso.programa==9 || caso.programa==4">Resumen de crédito</p>
           <span v-if="caso.programa==9 || caso.programa==4">Valor inmueble:{{caso.valorInmueble}}</span>
           <br v-if="caso.programa==9 || caso.programa==4">
            <span v-if="caso.programa==9 || caso.programa==4">Monto de crédito:{{caso.montoCredito}}</span>
              <div class="btn-fav">
                <svg class="heart fav-heart">
                  <path id="Vector" d="M19.855,0A7.63,7.63,0,0,0,13.75,3.066,7.634,7.634,0,0,0,0,7.686a14.683,14.683,0,0,0,.715,4.551C2.887,19.112,9.584,23.224,12.9,24.351a3,3,0,0,0,1.7,0c3.314-1.128,10.01-5.239,12.182-12.114A14.683,14.683,0,0,0,27.5,7.686,7.664,7.664,0,0,0,19.855,0Z" transform="translate(2.75 4.263)"/>
                </svg>
                <div class="fav-font cursor-pointer" @click="irImprimir()" v-if="caso.favoritos.length >0">Ver Favoritos ({{caso.favoritos.length}})</div> 
                <div class="fav-font cursor-pointer"  v-if="caso.favoritos.length==0">Ver Favoritos ({{caso.favoritos.length}})</div>
              </div>
            <!--<br>
            <p class="text-grey mb-0">Ordenar por</p>
            <div class="flex justify-between">
              <ul class="centerx vx-row filtros" v-if="caso.destino != 19 || caso.destino != 25 || caso.destino != 22 || caso.destino != 33 || caso.destino != 34">
                <li v-for="filtro in filtros"  :key="filtro.value" style="margin:2%;">
                  <vs-radio v-model="filtroModel" :vs-value="filtro.value" @change="filtrar(filtro.value)" v-if="filtro.value < 7">{{filtro.label}}</vs-radio>
                </li>
              </ul>
              <ul class="centerx vx-row filtros" v-else>
                <li v-for="filtro in filtros"  :key="filtro.value" style="margin:2%;">
                  <vs-radio v-model="filtroModel" :vs-value="filtro.value" @change="filtrar(filtro.value)">{{filtro.label}}</vs-radio>
                </li>
              </ul>
            </div>-->
					</div>
					<div id="cotizacion">
						<div class="opciones">
							<div class="row w2-full">
								<div class="thead-productos">
									<div class="col-md-2 banco-logo"></div>
									<div class="col-md-3 descripcion">Descripción</div>
									<div class="col-md-1 informacion"></div>
									<div class="col-md-2 ordenar">
										<span class="ordenar-titulo">Ordenar por<span class="ocultar">:</span></span>
										<div class="">
											<div class="" v-if="destino == 19 || destino == 25 || destino == 22 || destino == 33 || destino == 34">
												<select v-model="filtroModel" @change="filtrar()" class="vs-ordenar">
													<option :value="filtro.value" v-for="filtro in filtrosComplet" :key="filtro.value">{{ filtro.label }}</option>
												</select>
											</div>
											<div class="" v-else>
												<select v-model="filtroModel" @change="filtrar()" class="vs-ordenar">
													<option :value="filtro.value" v-for="filtro in filtros" :key="filtro.value">{{ filtro.label }}</option>
												</select>
											</div>
										</div>
									</div>
									<div class="col-md-2 acciones">Acciones</div>
									<div class="col-md-1 promociones">Promociones</div>
									<div class="col-md-1 producto"></div>
								</div>
							</div>
							<div class="relative">
								<div class="items-list-view" v-for="(item, index) in items" :key="item.objectID">
									<Productos :item="item" :filtroName="filtroName" :filtroId="filtroId" :idCaso="id" class="">
										<template slot="mejora" v-if="caso.destino == 19 || caso.destino == 25 || caso.destino == 22 || caso.destino == 33 || caso.destino == 34">
											<p class="text-md" style="color: green">Ahorro Mensual ${{ numberFormat(item.MejoraMensualidad) }}</p>
											<p class="text-md" style="color: green">Ahorro Total ${{ numberFormat(item.AhorroTotal > 0 ? item.AhorroTotal : 0) }}</p>
										</template>

										<template slot="cofinavit" v-if="destino == 1 && caso.programa == 4 && item.GastosInicialesCofinavit">
											<td class="td-modal">Gastos Iniciales con Cofinanciamiento Infonavit:</td>
											<td>${{ numberFormat(item.GastosInicialesCofinavit) }}</td>
										</template>
										<template slot="gastos" v-else>
											<td class="td-modal">Gastos Iniciales:</td>
											<td>${{ numberFormat(item.GastosIniciales) }}</td>
										</template>

										<template slot="action-buttons-2">
											<vx-tooltip text="Favoritos">
												<button class="favoritos" @click.prevent="agregarFavorito(item)" v-if="comprobarFavorito(item)">
													<svg class="heart">
														<path id="Vector" d="M19.855,0A7.63,7.63,0,0,0,13.75,3.066,7.634,7.634,0,0,0,0,7.686a14.683,14.683,0,0,0,.715,4.551C2.887,19.112,9.584,23.224,12.9,24.351a3,3,0,0,0,1.7,0c3.314-1.128,10.01-5.239,12.182-12.114A14.683,14.683,0,0,0,27.5,7.686,7.664,7.664,0,0,0,19.855,0Z" transform="translate(2.75 4.263)" />
													</svg>
													<!--<feather-icon icon="HeartIcon" :svgClasses="[{'text-danger fill-current' : !comprobarFavorito(item)}, 'h-4 w-4']" />-->
												</button>
												<button class="favoritos" icon-pack="feather" @click.prevent="quitarFavorito(item)" v-else><feather-icon icon="HeartIcon" :svgClasses="[{ 'text-danger fill-current': !comprobarFavorito(item) }, 'h-8 w-8']" /></button>
											</vx-tooltip>
										</template>
									</Productos>
								</div>
							</div>
						</div>
					</div>
				</vx-card>
				<vs-alert active="true" v-else> No hay resultados que coincidan con su consulta </vs-alert>
			</div>
			<div v-if="mostrarInfoSolicitante">
				<vx-card title="">
					<h4 class="titulo">Opciones de crédito</h4>
					<div class="row w-full">
						<div class="d-grid gap-2 d-md-flex justify-content-md-end">
							<button class="btn btn-primary" @click="regresar()">Regresar</button>
							<button class="btn btn-secondary" @click="ValidaSolicitante()" :disabled="!validar" id="btnGuardar">Guardar</button>
						</div>
					</div>
					<div class="row w-full">
						<div class="col-md-3">
							<label class="span-placeholder">Nombre</label>
							<input class="vs-input" v-validate="'required|alpha_spaces'" maxlength="20" v-model="nombre" name="nombres" />
							<!--<span class="text-danger text-sm" v-show="errors.has('nombres')">Campo requerido, solo caracteres</span>-->
						</div>
						<div class="col-md-3">
							<label class="span-placeholder">Segundo nombre</label>
							<input class="vs-input" v-validate="'alpha_spaces'" maxlength="20" v-model="segundoNombre" name="segundoNombre" />
							<!--<span class="text-danger text-sm" v-show="errors.has('segundoNombre')">Campo requerido, solo caracteres</span>-->
						</div>
						<div class="col-md-3">
							<label class="span-placeholder"> Apellido Paterno</label>
							<input class="vs-input" v-validate="'required|alpha_spaces'" maxlength="20" v-model="apellidoPaterno" name="apellidoPaterno" />
							<!--<span class="text-danger text-sm" v-show="errors.has('apellidoPaterno')">Campo requerido, solo caracteres</span> -->
						</div>
						<div class="col-md-3">
							<label class="span-placeholder"> Apellido Materno</label>
							<input class="vs-input" v-validate="'alpha_spaces'" maxlength="20" v-model="apellidoMaterno" name="apellidoMaterno" />
							<!-- <span class="text-danger text-sm" v-show="errors.has('apellidoMaterno')">Campo requerido, solo caracteres</span> -->
						</div>
					</div>
					<div class="row w-full">
						<div class="col-md-3">
							<label class="span-placeholder">Celular (10 dígitos)</label>
							<input class="vs-input" v-model="telefono" name="telefono" maxlength="10" v-validate="'numeric|min:10|max:10'" v-on:keypress="isNumber($event)" />
							<!--<span class="text-danger text-sm" v-show="errors.has('telefono')">El campo debe tener 10 caracteres</span>-->
						</div>
						<div class="col-md-3">
							<label class="span-placeholder">Correo electrónico</label>
							<input class="vs-input" v-model="email" v-validate="'required|email'" name="email" />
							<!-- <span class="text-danger text-sm" v-show="errors.has('email')">Campo requerido, debe ser un email válido</span>    -->
						</div>
						<div class="col-md-3">
							<label class="span-placeholder">CURP</label>
							<input class="vs-input" v-model="curp" name="curp" v-validate="'min:18|max:18'" v-mask="'AAAA######AAAAAANN'" value=""  onkeyup="javascript:this.value=this.value.toUpperCase();"/>
							<span class="text-danger text-sm" style="display: none" id="errorCurp">Campo requerido, debe ser un CURP válido</span>
						</div>
					</div>
				</vx-card>
			</div>
			<div class="modal fade" id="modalTabla" ref="table" size="sm" hide-footer hide-header>
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-body">
							<div class="row">
								<div class="d-md-flex justify-content-md-center"><h1 style="color: #00368c">Folio de simulación</h1></div>
							</div>
							<div class="d-md-flex justify-content-md-center" id="icono"></div>
							<div class="row">
								<div class="d-md-flex justify-content-md-center">
									<h1 style="color: #00368c">{{ id }}</h1>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    </div>
        <div class="vista">
        <vx-card title="" class="" v-if="!mostrarBoton">
          <div class="confirmacion-phone text-center">
              <p>Escribe el código que recibiste en tu teléfono</p>
              <input id="uno" type="text" v-model="numUno" maxlength="1" minlength="1" class="mb-2" placeholder="" @keyup="cambiarInput()">
              <input id="dos" type="text" v-model="numDos" maxlength="1" minlength="1" class="mb-2" placeholder="" @keyup="cambiarInput()">
              <input id="tres" type="text" v-model="numTres" maxlength="1" minlength="1" class="mb-2" placeholder="" @keyup="cambiarInput()">
              <input id="cuatro" type="text" v-model="numCuatro" maxlength="1" minlength="1" class="mb-2" placeholder="">
              <a @click="generarCodigo()" class="d-block blue text-center mb-4">Reenviar código</a>
              <div class="d-flex justify-content-center justify-content-md-end">
                <a  class="mr-2 btn-back" @click="regresarSms()" >Regresar</a>
                <vs-button  @click="validaCodigo()" :disabled="!infoCompleta" color="primary">Solicitar</vs-button>
                  <div class="alert alert-success alert-margin" @click="closeAlert('exito')" role="alert " :class="alertExito? '' : 'ocultarMensaje' ">
                    {{mensajeExito}}
                  </div>
                  <div class="alert alert-danger" @click="closeAlert('error')"  role="alert" :class="alertError? '' : 'ocultarMensaje' ">
                    {{mensajeError}}
                  </div>  
              </div>
              <!--<a  class="close"><img src="../../../assets/images/icon-close.svg" width="15px" alt="close"></a>  -->   
            </div> 
        </vx-card><!-- Agregar sms -->
        </div>

		</div>
	</div>
</template>

<script>
import Productos from '@/views/Caso/Productos.vue'
import Adquisicion from '@/views/Destinos/AdquisicionPreca.vue'
import Mejora from '@/views/Destinos/MejoraPreca.vue'
import AdquisicionCofi from '@/views/Destinos/AdquisicionCofi.vue'
import AdquisicionAliados from '@/views/Destinos/AdquisicionAliados.vue'
import Terreno from '@/views/Destinos/TerrenoPreca.vue'
import TerrenoConstruccion from '@/views/Destinos/TerrenoConstruccion.vue'
import TerrenoProgramas from '@/views/Destinos/TerrenoProgrmas.vue'
import vSelect from 'vue-select'

export default {
  components: {
    Productos,
    Adquisicion,
    Mejora,
    AdquisicionCofi,
    AdquisicionAliados,
    Terreno,
    TerrenoProgramas,
    TerrenoConstruccion,
    'v-select': vSelect,
  },
    props: {
    emailN: {
      type: String,
      required: false
    },
    telN: {
      type: String,
      required: false
    },
    IdN: {
      type: String,
      required: false
    },
    IngresoN:{
      type: Boolean,
      required: false
    },
    CambioVistaN: {
      type: String,
      required: false
    },
    
   },
  data(){
		return{
      id:'',
      items:[],
      destino:1,
      destinos:[],
      completeInfoCirculo:true,
      emailCasoNuevo:{'bandera':'', 'Email':'', 'tel':'', 'Id':''},
      updateIngresos:false,
      mostrarBoton:true,
      completeInfo:false,
      numUno:'',
      numDos:'',
      numTres:'',
      numCuatro:'',
      alertExito:false,
      alertError:false,
      activeConfirm: false,
      mensajeError:'',
      mensajeExito:'',
      SmsEnvio:false,
      telefonoCelular:'',
      ladaPais:'',
     // bancosEjecutivo:[],
      bancosEjecutivo:[{label:'CITIBANAMEX',value:2},
                      {label:'BANORTE',value:4},
                      {label:'HSBC',value:6},
                      {label:'SANTANDER',value:10},
                      {label:'SCOTIABANK',value:11}],
      bancos:[],
      programas:[],
      tasas:[],
      caso:{
        valor:'',
        credito:'',
        destino:1,
        plazos:[],
        esquemas:[],
        plazoRestante:'',
        mensualidad:'',
        programa:1,
        bancos:[],
        tasa:2,
        favoritos:[],
        ssv:'',
        creditoInf:'',
        gastosNotarial:6,
        montoCredito:0,
        estado:''
      },
      filtroModel:1,
      filtroName:'',
      filtroId:'',
      filtros:[
        { label: 'Mensualidad',  value: '1' },
        { label: 'Cat',  value: '2' },
        { label: 'Tasa',  value: '3' },
        { label: 'Pago total',  value: '4' },
        { label: 'Con promociones',  value: '5' },
        { label: 'Mayor ahorro total',  value: '7' },
        { label: 'Mayor ahorro mensual',  value: '8' },
        { label: 'Ingreso Minimo',  value: '6' },
      ],
      gastosNotariales:[{label : '1 %', value : '1'},
                        {label : '2 %', value : '2'},
                        {label : '3 %', value : '3'},
                        {label : '4 %', value : '4'},
                        {label : '5 %', value : '5'},
                        {label : '6 %', value : '6'},
                        {label : '7 %', value : '7'},
                        {label : '8 %', value : '8'},
                        {label : '9 %', value : '9'},
                        {label : '10 %', value : '10'}
      ],
      otro:0,
      IngresoSol:0,
      estados:[],
      mostrarInfoSolicitante:false,
      mostrarOpciones:false,
      mostrarDatosCotizacion:true,
      nombre:'',
      segundoNombre:'',
      apellidoPaterno:'',
      apellidoMaterno:'',
      telefono:'',
      email:'',
      curp:'',
      emailEjecutivo:'',
      mensajeModal:'',  
      // header: {
      //     "authorization": "Bearer 16JXvQ9CD1eEoVESY3-4d-bElrAAgAfcB0UgYwAB-XyuNugkcBvgntr6n6VjEAFWenS9Exi_jlLv_RBfmf004TXytHP64dNEuSyhlXOBDtO56hW07txWd4evNWfukK5CO1ETRIOk8GWyjjS7daQ8eLHUz3XefoKEHKE0yIy76o8rZZYTGskAn1Zo3oKcPFzfs_fmVlO15cD_Kxx27-1qqDBsfAphhY7N599ly5ppo5L4XHaL8JAv6mTxXVYxrTZPQDmnLqQ9Nwre7Bd4URM5rR3rVMrAVkPzJ8ohQ9-pgvigB-Fra_SMi31c5T-QrSTB"
      //   },
      header : {},
      SocGuid: '',
      BrokerId:'',
      IdLiga: '',
      GastoNotarialItem:0,
      consultasDisponibles:'',
      ingresosSolicitante:'',
      ProgramaDefaul:false,
      EstadoDom:'',
      MontoValorVivienda:true,
      MontoValorCredito:true,
      MontoValorCredito2:true,
      MontoValorCredito3:true,
      
    }
  
  },
   watch: {
      CambioVistaN:function(){
      if(this.CambioVistaN =='Credito'){
       
        this.dameSolicitante()
      }
    }

   },
  computed:{
    validateForm(){
      return this.MontoValorVivienda==true && this.MontoValorCredito==true && this.MontoValorCredito2==true && this.MontoValorCredito3==true
      
    },
    emailState() {
      const regex = /^[-\w.%+]{1,64}@(?!socasesores)(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      const matches = regex.test(this.email);
      return this.email == null || matches ? true:false
    },
    celphoneState() {
      return this.telefono == null || this.telefono.length == 10 ? true : false
    },
    nameState() {
      return   this.nombre==null || this.nombre.length >= 3 ? true : false
    },
    lastNameState() {
      return this.apellidoPaterno == null || this.apellidoPaterno.length > 2 ? true : false
    },
     /* emailState() {
      const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      const matches = regex.test(this.correo);
      return this.correo == null || matches ? true:false
    },*/
       validar(){
           return this.emailState && this.lastNameState && this.nameState && this.celphoneState 
       },
        infoCompleta(){
      return this.numUno.length > 0 && this.numDos.length > 0 && this.numTres.length > 0 && this.numCuatro.length > 0
    },

    


  },
  mounted(){

      if(!this.$route.params.id){
          this.getApikey()
         

          
          if(this.emailN!=''){
              this.update()
              this.email=this.emailN
              this.telefonoCelular= this.telN
              this.dameSolicitante()
          }
       
            if(this.emailN!=''){
            this.email=this.emailN
            this.dameSolicitante()
          }

            this.dameSolicitante()
            this.getDestinos()
            this.getProgramas()
            this.getBancos()
            this.getTasas()
             this.DameCredenciales()


       }else{
         
           this.id=this.$route.params.id
            this.getApikey()
            this.dameSolicitante()
            this.dameCaso()

            this.getDestinos()
            this.getProgramas()
            this.getBancos()
            this.getTasas()
             this.DameCredenciales()


       }

  },
  methods:{
    async getTokenSisec() {
      let objRequestToken = {
        strApiKey: this.key,
        strMetodo: 'DameAuthToken',
      }
      await this.$axios.post('/', objRequestToken,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then( response => {
          // console.log('getTokenSisec', response.data.access_token );
          if (!response.data.error) {
            this.header = { "Authorization" : "Bearer "+ response.data.access_token};
          } else {
            this.$vs.notify({
              title: 'Ocurrio un error al obtener token',
              text: response.data.error_description,
              color: 'danger',
              position: 'top-right',
            })
          }
        }).catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema',
            text: error,
            color: 'danger',
            position: 'top-right',
          })
        });
    },
    closeAlert(alerta){
      switch (alerta) {
       case 'exito':
         this.alertExito=false
         break;
       case 'error':
         this.alertError=false
         break;
       default:
         break;
     }
    },
    seleccionarTodos(){
      this.caso.bancos=this.bancos
    }, 
    limpiarBancos(){
      this.caso.bancos=[]
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    irImprimir(){
       localStorage.setItem('vistaNuevaCotizacion', true)
      this.$router.push('/imprimir/'+this.id);
    },
    pruebaIon(plazo, destinoId){
     /* product i.	Adquisición de vivienda = REAL_STATE
      ii.	Liquidación = LIQUIDITY
      iii.	Oficinas y locales = OFFICES_AND_STORES
      iv.	Adquisición de terreno = LAND
      */
     let self= this
     let destino=''
     let GastosInicialCalculado= ''
     let NombreProducto=''
     let amount =''
     let respuestaData=''
     let calculoAforo=''

			calculoAforo = (this.caso.montoSolicitado.replace(/,/g, '') * 100) / this.caso.valorInmueble.replace(/,/g, '')

			switch (destinoId) {
				case 1:
					destino = 'REAL_ESTATE'
					GastosInicialCalculado = this.caso.valorInmueble.replace(/,/g, '') * 0.08
					NombreProducto = 'ION Adquisición Plus'
					break
				case 3:
					destino = 'LIQUIDITY'
					GastosInicialCalculado = this.caso.valorInmueble.replace(/,/g, '') * 0.03
					NombreProducto = 'ION Liquidez Plus'
					break
				case 5:
					destino = 'LAND'
					GastosInicialCalculado = this.caso.valorInmueble.replace(/,/g, '') * 0.08
					NombreProducto = 'ION Adquisición de Terreno Plus'
					break
				case 37:
					destino = 'OFFICES_AND_STORES'
					GastosInicialCalculado = this.caso.valorInmueble.replace(/,/g, '') * 0.08
					NombreProducto = 'ION Oficinas y Locales Plus'
					break
			}
			var objGeneraCodigo = {
				person_type: 'NATURAL', //valor fijo
				product: destino, //producto
				required_amount: { amount: this.caso.montoSolicitado.replace(/,/g, ''), currency: 'MXN' }, // cantidad requerida
				monthly_income: { amount: 50000000, currency: 'MXN' }, //ingreso mensual valor fijo
				real_estate_value: { amount: this.caso.valorInmueble.replace(/,/g, ''), currency: 'MXN' }, //valor inmobiliario
				term: parseInt(plazo),
			}
			this.$axios
				.post('https://cotizador.socasesores.com/ApiION/MethodoIon.php', objGeneraCodigo, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {
					respuestaData = JSON.parse(response.data.objRespuesta)

					/* if(respuestaData.notification.level=='ERROR'){

                 this.mostrarOpciones=true
                this.mostrarDatosCotizacion=false
                this.$vs.loading.close('#cotizacion > .con-vs-loading')
                if(localStorage.getItem('regreso')){
                  localStorage.removeItem('vistaNuevaCotizacion')
                  localStorage.removeItem('regreso')
                }

              }
              else*/
					if (respuestaData.message == 'Internal server error') {
						this.mostrarOpciones = true
						this.mostrarDatosCotizacion = false
						this.$vs.loading.close('#cotizacion > .con-vs-loading')
						if (localStorage.getItem('regreso')) {
							localStorage.removeItem('vistaNuevaCotizacion')
							localStorage.removeItem('regreso')
						}

						self.$vs.notify({
							title: 'Internal server error ION',
							text: error,
							color: 'danger',
							position: 'top-right',
						})
					} else {
						var respuestaIon = JSON.parse(response.data.objRespuesta)
						let itemsIon = {}
						let items = []
						let cantidadCotiza = items.length
						itemsIon.Cat = parseFloat(respuestaIon.data.cat)
						itemsIon.Mensualidad = parseFloat(respuestaIon.data.monthly_payment.amount)
						itemsIon.Tasa = parseFloat(respuestaIon.data.interest_rate)
						itemsIon.IngresoMinimo = parseFloat(respuestaIon.data.minimum_income) // parseFloat(respuestaIon.data.monthly_payment.amount)/33
						itemsIon.Enganche = parseFloat(this.caso.valorInmueble.replace(/,/g, '') - this.caso.montoSolicitado.replace(/,/g, ''))
						itemsIon.GastosIniciales = parseFloat(respuestaIon.data.initial_expenses)
						itemsIon.Aforo = calculoAforo
						itemsIon.Plazo = respuestaIon.data.term * 12
						itemsIon.PagoTotal = parseFloat(respuestaIon.data.total_payment)
						itemsIon.Monto = parseFloat(this.caso.montoSolicitado.replace(/,/g, ''))
						itemsIon.MensualidadPromedio = parseFloat(respuestaIon.data.monthly_payment.amount)
						itemsIon.MensualidadInicial = parseFloat(respuestaIon.data.monthly_payment.amount)
						itemsIon.BancoId = 21
						itemsIon.Banco = 'ION'
						itemsIon.Producto = NombreProducto
						itemsIon.Esquema = 2
						itemsIon.Logo = 'LogoIon_130622.png'
						itemsIon.PdfAmortizacion = respuestaIon.data.amortization_table.file
						itemsIon.JsonAmortizacion = respuestaIon.data.amortization_table.raw_data
						itemsIon.IdIon = respuestaIon.data.id
						this.items.push(itemsIon)
						//itemsIon.push(parseInt(this.caso.esquemas[i].value))

						this.items.sort((a, b) => (a.MensualidadPromedio > b.MensualidadPromedio ? 1 : b.MensualidadPromedio > a.MensualidadPromedio ? -1 : 0))
						this.mostrarOpciones = true
						this.mostrarDatosCotizacion = false
						this.$vs.loading.close('#cotizacion > .con-vs-loading')
						if (localStorage.getItem('regreso')) {
							localStorage.removeItem('vistaNuevaCotizacion')
							localStorage.removeItem('regreso')
						}
					}

					this.$vs.loading.close('#cotizacion > .con-vs-loading')
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un errros en apiIon',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		registrarSolicitante() {
			let self = this
			/*this.$vs.loading({
        container: '#cotizacion',
        scale: 0.6
      })*/
			let objRequestRegistraSolicitante = {
        strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraSolicitante',
				objSolicitante: {
					EMail: this.email,
					Nombres: this.nombre,
					SegundoNombre: this.segundoNombre,
					ApellidoPaterno: this.apellidoPaterno,
					ApellidoMaterno: this.apellidoMaterno,
					TelefonoCelular: this.telefono,
					FechaNacimiento: '1992-01-01',
          StateData: 0,
					Curp: this.curp,
				},
			}
			this.$axios
				.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.dameInformacion()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraSolicitante',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		ValidaSolicitante() {
			let self = this

			if (this.curp != '') {
			//	if (this.curpValida(this.curp)) {
					this.GeneraLiga()
			/*	} else {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: 'El curp no tiene el formato correcto',
						color: 'danger',
						position: 'top-right',
					})
				}*/
			} else {
				document.getElementById('btnGuardar').style.display = 'none'
				this.registrarSolicitante()
			}
		},
     RegresarDomicilio(){

   
			this.emailCasoNuevo.bandera= 'DomicilioRegresar'
			this.emailCasoNuevo.Email= this.email
			this.emailCasoNuevo.tel= this.telefono
      this.emailCasoNuevo.Id= this.Id

					
			this.$emit("bandera",  this.emailCasoNuevo)

		},
		regresar() {
			this.mostrarDatosCotizacion = false
			this.mostrarInfoSolicitante = false
			this.mostrarOpciones = true
		},
		regresarAcotizacion() {
			this.mostrarDatosCotizacion = true
			this.mostrarInfoSolicitante = false
			this.mostrarOpciones = false
		},
		mostrarFormSolicitante() {
			this.mostrarDatosCotizacion = false
			this.mostrarInfoSolicitante = true
			this.mostrarOpciones = false
		},
		getEstados() {
			let self = this
			var objRequestListaEstados = {
				strApiKey: this.key,
				strMetodo: 'ListaEstados',
			}
			this.$axios
				.post('/', objRequestListaEstados, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.estados = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaEstados',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		registraAdicionales() {
			let self = this
			var objRequestAdicionales = {
				strApiKey: this.key,
				strMetodo: 'RegistraAdicionalesSolicitud',
				objCaso: {
					Id: this.id,
					InmuebleGarantia: {
						//InmuebleNuevo:this.caso.inmuebleNuevo,
						Estado: this.caso.estado,
					},
				},
			}
			this.$axios
				.post('/', objRequestAdicionales, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Guardardo exitosamente`,
							position: 'top-right',
						})
						//this.dameCaso()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraAdicionalesSolicitud',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		mostrarProgramas() {
			this.programas = []
			if (this.caso.destino == 1 || this.caso.destino == 5 || this.caso.destino == 2) {
				this.programas = this.todosProgramas
			}
       else if (this.caso.destino == 20) {
				this.programas = []
				var auxProgramas = [1,6]
				var auxProgramaExiste = this.todosProgramas
				for (var j = 0; j < auxProgramaExiste.length; j++) {
					if (auxProgramas.includes(auxProgramaExiste[j]['Id'])) {
						this.programas.push(auxProgramaExiste[j])
					}
				}
			}
       else {
				this.programas = []
				var auxProgramas = [1, 9, 4]
				var auxProgramaExiste = this.todosProgramas
				for (var j = 0; j < auxProgramaExiste.length; j++) {
					if (auxProgramas.includes(auxProgramaExiste[j]['Id'])) {
						this.programas.push(auxProgramaExiste[j])
					}
				}
			}
		},
		cambia() {
			this.otro = this.otro + 1
		},
		factorySettings() {
			this.items = []
		},
		numberFormat(number) {
			return number
				.toFixed(2)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		},
		update(e) {
			if (e.otro) {
				this.otro = 0
			}

      this.MontoValorVivienda=e.MontoValorVivienda
      this.MontoValorCredito=e.MontoValorCredito
      this.MontoValorCredito2=e.MontoValorCredito2
      this.MontoValorCredito3=e.MontoValorCredito3
      
           this.IngresoSol=this.IngresoSol
           this.updateIngresos=true
            
			this.caso.plazos = []
			this.caso.esquemas = []
			for (var i = 0; i < e.plazosACotizar.length; i++) {
				if (typeof e.plazosACotizar[i] == 'object') {
					this.caso.plazos.push(e.plazosACotizar[i].value)
				} else {
					this.caso.plazos.push(e.plazosACotizar[i].substring(0, 2))
				}
			}
			for (var i = 0; i < e.esquemasACotizar.length; i++) {
				this.caso.esquemas.push(parseInt(e.esquemasACotizar[i].value))
			}
			if (e.destino == 19 || e.destino == 25 || e.destino == 22 || e.destino == 33 || e.destino == 34) {
				this.caso.valorInmueble = e.valor
				this.caso.montoSolicitado = e.credito
				this.caso.pagoMensualActual = e.mensualidad
				this.caso.plazoRestante = e.plazoRestante
			} else if (e.destino == 11) {
				this.caso.valorInmueble = e.valor
				this.caso.montoSolicitado = '0'
				this.caso.ssv = e.ssv
				this.caso.creditoInf = e.creditoInf
				if (this.caso.programa == 9 || this.caso.programa == 4) {
					this.caso.montoCredito = e.montoCredito
				}
			} else {
				// this.destino=e.destino
      
				this.caso.valorInmueble = e.valor
				this.caso.montoSolicitado = e.credito
			}
   
		},
		cambiaBancos(e) {
			this.bancos = []
			this.caso.plazos = []
			this.caso.esquemas = []
			for (var i = 0; i < e.plazosACotizar.length; i++) {
				if (typeof e.plazosACotizar[i] == 'object') {
					this.caso.plazos.push(e.plazosACotizar[i].value)
				} else {
					this.caso.plazos.push(e.plazosACotizar[i].substring(0, 2))
				}
			}
			for (var i = 0; i < e.esquemasACotizar.length; i++) {
				this.caso.esquemas.push(parseInt(e.esquemasACotizar[i].value))
			}

			let self = this
			let objRequestDamePlazos = {
				strApiKey: this.key,
				strMetodo: 'ListaBancosPlazos',
				objCaso: {
					Esquema: this.caso.esquemas,
					Plazos: this.caso.plazos,
				},
			}
			this.$axios
				.post('/', objRequestDamePlazos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						let bancos = response.data.objContenido
						for (var i = 0; i < bancos.length; i++) {
							this.bancos.push({ label: bancos[i].Nombre, value: bancos[i].Id })
						}
						this.caso.bancos = this.bancosEjecutivo //cambio bancos
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaFinancieras',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error en DamePlazos',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		callMethodInParentComponent() {
			this.$emit('callMethodInParent')
			// alert("hola");
		},
		cotiza(id, destinoId) {
			this.$vs.loading({
				container: '#cotizacion',
				scale: 0.6,
			})

      let self=this
     
      let objRequestCotizaCaso = {
        strApiKey: this.key,
        strMetodo: 'Cotiza',
        objCaso: {
         Id: id,
         //GastoNotarial: this.caso.gastosNotarial
        }
      }
      this.$axios.post('/',objRequestCotizaCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){

            //Mejora ion
            let bancos=[]
            let esquemas=[]

						for (var i = 0; i < this.caso.bancos.length; i++) {
							if (this.caso.bancos[i].value) {
								bancos.push(parseInt(this.caso.bancos[i].value))
							} else {
								bancos.push(parseInt(this.caso.bancos[i]))
							}
						}

						for (var i = 0; i < this.caso.esquemas.length; i++) {
							if (this.caso.esquemas[i].value) {
								esquemas.push(parseInt(this.caso.esquemas[i].value))
							} else {
								esquemas.push(parseInt(this.caso.esquemas[i]))
							}
						}

          ////////////////////////////*************
         for(var j=0; j<this.caso.plazos.length;j++){
           if((this.caso.programa==1 &&  esquemas.includes(2) && bancos.includes(21) && (destinoId==1 || destinoId==3) && (this.caso.plazos[j]==5 || this.caso.plazos[j]==6 || this.caso.plazos[j]==7 || this.caso.plazos[j]==8 || this.caso.plazos[j]==9 || this.caso.plazos[j]==10 || this.caso.plazos[j]==11 || this.caso.plazos[j]==12 || this.caso.plazos[j]==13 || this.caso.plazos[j]==14 || this.caso.plazos[j]==15 || this.caso.plazos[j]==16 || this.caso.plazos[j]==17 || this.caso.plazos[j]==18 || this.caso.plazos[j]==19 || this.caso.plazos[j]==20)) || (this.caso.programa==1 &&  esquemas.includes(2) && bancos.includes(21) && (destinoId==5 || destinoId==37) && (this.caso.plazos[j]==5 || this.caso.plazos[j]==6 || this.caso.plazos[j]==7 || this.caso.plazos[j]==8 || this.caso.plazos[j]==9 || this.caso.plazos[j]==10))){
             
                  //console.log(this.caso.plazos)
                  //alert(this.caso.plazos[j]+'-'+j)
                  this.pruebaIon(this.caso.plazos[j] , destinoId)
                

                this.items= response.data.objContenido
                this.items.sort((a,b) => (a.MensualidadPromedio > b.MensualidadPromedio) ? 1 : ((b.MensualidadPromedio > a.MensualidadPromedio) ? -1 : 0));
                this.filtroName= 'Mensualidad'
                this.filtroId=1
                if(this.destino.Id == 19){
                  for (var i = 0; i < this.items.length; i++) {
                    this.items[i].MejoraMensualidad= this.items[i].MejoraMensualidad
                    this.items[i].AhorroTotal= this.items[i].AhorroTotal
                  }
                }

                  this.$vs.loading({
                    container: '#cotizacion',
                    scale: 0.6
                  }) 

           }else{

             
                  this.items= response.data.objContenido
                  this.items.sort((a,b) => (a.MensualidadPromedio > b.MensualidadPromedio) ? 1 : ((b.MensualidadPromedio > a.MensualidadPromedio) ? -1 : 0));
                  this.filtroName= 'Mensualidad'
                  this.filtroId=1
                  if(this.destino.Id == 19){
                    for (var i = 0; i < this.items.length; i++) {
                      this.items[i].MejoraMensualidad= this.items[i].MejoraMensualidad
                      this.items[i].AhorroTotal= this.items[i].AhorroTotal
                    }
                  }

                

                    for (var i = 0; i < this.items.length; i++) {
                    this.items[i].GastosNotariales= (this.caso.gastosNotarial / 100) * parseFloat(this.caso.valorInmueble.replace(/,/g,""))
                     this.items[i].GastosIniciales= this.items[i].Avaluo + this.items[i].ComisionApertura + this.items[i].GastosNotariales
                  }
                 

           }
          }
         ////////////////////////////**************

                   this.items= response.data.objContenido
                  this.items.sort((a,b) => (a.MensualidadPromedio > b.MensualidadPromedio) ? 1 : ((b.MensualidadPromedio > a.MensualidadPromedio) ? -1 : 0));
                  this.filtroName= 'Mensualidad'
                  this.filtroId=1
                   this.mostrarOpciones=true
                  this.mostrarDatosCotizacion=false
                  this.$vs.loading.close('#cotizacion > .con-vs-loading')
                  if(localStorage.getItem('regreso')){
                    localStorage.removeItem('vistaNuevaCotizacion')
                    localStorage.removeItem('regreso')
                  }


          }else {
            this.$vs.notify({
              title:'Ocurrio un error en Cotiza',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    updateCaso(id){
      let self=this
      let objRequestRegistraCaso1= {}
      let bancos=[]
      this.caso.montoCredito=this.caso.montoCredito.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if(this.caso.montoCredito!==0 ){  //no usar replace cuando es 0
        var auxMontoCredito=parseFloat(this.caso.montoCredito.replace(/,/g,""))
        this.caso.montoCredito=auxMontoCredito
      }
      for (var i = 0; i < this.caso.bancos.length; i++) {
        bancos.push((this.caso.bancos[i].value).toString())
      }
      if(this.caso.destino == '19' || this.caso.destino == '25' || this.caso.destino == '22' || this.caso.destino == '33' || this.caso.destino == '34'){
       objRequestRegistraCaso1 = {
          strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
          strMetodo: 'RegistraCaso',
          objCaso: {
            Id:id,
            Broker:'demoSoc@socasesores.com',
            ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
            SaldoInsoluto : parseFloat(this.caso.montoSolicitado.replace(/,/g,"")),
            Plazos : this.caso.plazos,
            Solicitante: 'solicitante@socasesores.com',
            Destino : this.caso.destino,
            Esquemas:this.caso.esquemas,
            PlazoRestante:this.caso.plazoRestante,
            PagoMensualActual: parseFloat(this.caso.pagoMensualActual.replace(/,/g,"")),
            Bancos:bancos,
            PerfilCliente : this.caso.tasa,
            VersionOrigen:'Web rapida'
          }
        }
      }else if ((this.caso.destino == 1 || this.caso.destino == 5 || this.caso.destino == 2 )  && ( this.caso.programa == 6)) {
        objRequestRegistraCaso1 = {
          strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
          strMetodo: 'RegistraCaso',
          objCaso: {
            Id:id,
            Broker:'demoSoc@socasesores.com',
            ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
            MontoSolicitado : parseFloat(this.caso.montoSolicitado.replace(/,/g,"")),
            Plazos : this.caso.plazos,
            Solicitante: 'solicitante@socasesores.com',
            Destino : this.caso.destino,
            Esquemas:this.caso.esquemas,
            Programas:[this.caso.programa],
            Bancos:bancos,
            PerfilCliente : this.caso.tasa,
            VersionOrigen:'Web rapida',
            //MontoCredito: parseFloat(this.caso.montoCredito.replace(/,/g,""))
          }
        }
      }else if ((this.caso.destino == 1 || this.caso.destino == 5 || this.caso.destino == 2 || this.caso.destino == 20)  && (this.caso.programa == 4 || this.caso.programa == 9 )) {
        objRequestRegistraCaso1 = {
          strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
          strMetodo: 'RegistraCaso',
          objCaso: {
            Id: id,
            ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
            MontoSolicitado : 0,
            Plazos : this.caso.plazos,
            Solicitante: 'solicitante@socasesores.com',
            Broker:'demoSoc@socasesores.com',
            Destino : this.caso.destino,
            CreditoInfonavit:parseFloat(this.caso.creditoInf.replace(/,/g,"")),
            SubCuentaVivienda:parseFloat(this.caso.ssv.replace(/,/g,"")),
            Esquemas:this.caso.esquemas,
            Bancos:bancos,
            Programas:[this.caso.programa],
            PerfilCliente : this.caso.tasa,
            VersionOrigen:'Web rapida',
            MontoCredito: this.caso.montoCredito,
            ValorTerreno: parseFloat(this.caso.valorTerreno.replace(/,/g, '')),
						ValorProyecto: parseFloat(this.caso.valorProyecto.replace(/,/g, '')),
          }
          
        }
      }else {
        objRequestRegistraCaso1 = {
          strApiKey: 'c7e0233e4a9c856d50662005a1c6cfbb',
          strMetodo: 'RegistraCaso',
          objCaso: {
            Id:id,
            Broker:'demoSoc@socasesores.com',
            ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
            MontoSolicitado : parseFloat(this.caso.montoSolicitado.replace(/,/g,"")),
            Plazos : this.caso.plazos,
            Solicitante: 'solicitante@socasesores.com',
            Destino : this.caso.destino,
            Esquemas:this.caso.esquemas,
            Programas:[this.caso.programa],
            Bancos:bancos,
            PerfilCliente : this.caso.tasa,
            VersionOrigen:'Web rapida'
          }
        }
      }
      this.$axios.post('/',objRequestRegistraCaso1,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.id=response.data.objContenido.Id
            this.registraAdicionales()
            this.cotiza(response.data.objContenido.Id, response.data.objContenido.Destino )
            if(localStorage.getItem('regreso')){
              this.dameCaso()
            }else{
              this.dameCaso(true)
            } 
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }

      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
   
    registrarCaso(){
     
     
      let self=this
      this.bancos=[2,4,6,10,11]
      this.esquemas=[2]
      this.plazos=[20]
      //this.programas=[1]

      this.items=[]

       if(this.caso.montoCredito!==0){  //no usar replace cuando es 0
        var auxMontoCredito=parseFloat(this.caso.montoCredito.replace(/,/g,""))
        this.caso.montoCredito=auxMontoCredito
      }

      if(this.emailN!=''){
        this.email=this.emailN
     
      }
        
        let objRequestRegistraCaso1= {}



        if(this.caso.destino == '19' || this.caso.destino == '25' || this.caso.destino == '22' || this.caso.destino == '33' || this.caso.destino == '34'){
          objRequestRegistraCaso1 = {
            strApiKey: this.key,
            strMetodo: 'RegistraCaso',
            objCaso: {
             Id: this.id,
            //  Broker:'demoSoc@socasesores.com',
              ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
              SaldoInsoluto : parseFloat(this.caso.montoSolicitado.replace(/,/g,"")),
              Plazos : this.caso.plazos,
              Solicitante: this.email,
              Destino : this.caso.destino,
              Esquemas:this.esquemas,
              PlazoRestante:this.caso.plazoRestante,
              PagoMensualActual: parseFloat(this.caso.pagoMensualActual.replace(/,/g,"")),
              Bancos:this.bancos,
              VersionOrigen:'Precalificación SOC',
              GastoNotarial: 6,
              PrecalificacionSoc:'PrecalificacionSoc',
              Programas:this.caso.programas,
              PerfilCliente : 2,
             


            }
          }
        }else if ((this.caso.destino == 1 || this.caso.destino == 5 || this.caso.destino == 2 )  && ( this.caso.programa == 6)) {
          objRequestRegistraCaso1 = {
            strApiKey: this.key,
            strMetodo: 'RegistraCaso',
            objCaso: {
              Id: this.id,
             // Broker:'demoSoc@socasesores.com',
              ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
              MontoSolicitado : parseFloat(this.caso.montoSolicitado.replace(/,/g,"")),
              Plazos : this.caso.plazos,
              Solicitante: this.email,
              Destino : this.caso.destino,
              Esquemas:this.esquemas,
              Programas:[this.caso.programa],
              Bancos:this.bancos,
              PerfilCliente : 2,
              VersionOrigen:'Precalificación SOC',
              GastoNotarial: 6,
              PrecalificacionSoc:'PrecalificacionSoc',
              
              //FechaPrecalificacionSoc:Date.now()
            }
          }
        }else if ((this.caso.destino == 1 || this.caso.destino == 5 || this.caso.destino == 2 || this.caso.destino == 20)  && (this.caso.programa == 4 || this.caso.programa == 9 )) {
          objRequestRegistraCaso1 = {
            strApiKey: this.key,
            strMetodo: 'RegistraCaso',
            objCaso: {
              Id: this.id,
              ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
              MontoSolicitado : 0,
              Plazos : this.caso.plazos,
              Solicitante: this.email,
              //Broker:'demoSoc@socasesores.com',
              Destino : this.caso.destino,
              CreditoInfonavit:parseFloat(this.caso.creditoInf.replace(/,/g,"")),
              SubCuentaVivienda:parseFloat(this.caso.ssv.replace(/,/g,"")),
              Esquemas:this.esquemas,
              Bancos:this.bancos,
              Programas:[this.caso.programa],
              PerfilCliente : 2,
              VersionOrigen:'Precalificación SOC',
              MontoCredito: this.caso.montoCredito,
              GastoNotarial: 6,
              PrecalificacionSoc:'PrecalificacionSoc',
             
              //FechaPrecalificacionSoc:Date.now()

            }
          }
        }else {
        
          objRequestRegistraCaso1 = {
            strApiKey: this.key,
            strMetodo: 'RegistraCaso',
            objCaso: {
              Id:this.id,
             // Broker:'demoSoc@socasesores.com',
              ValorInmueble : parseFloat(this.caso.valorInmueble.replace(/,/g,"")),
              MontoSolicitado : parseFloat(this.caso.montoSolicitado.replace(/,/g,"")),
              Plazos : this.caso.plazos,
              Solicitante: this.email,
              Destino : this.caso.destino,
              Esquemas:this.esquemas,
              Programas:[this.caso.programa],
              Bancos:this.bancos,
              PerfilCliente : 2,
              VersionOrigen:'Precalificación SOC',
              GastoNotarial: 6,          
              PrecalificacionSoc:'PrecalificacionSoc',             
              //FechaPrecalificacionSoc:Date.now(),
           

            }
          }
        }
        this.$axios.post('/',objRequestRegistraCaso1,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              /*localStorage.setItem('caso', response.data.objContenido.Id)
              this.id=response.data.objContenido.Id
              this.registraAdicionales()
              this.cotiza(response.data.objContenido.Id, response.data.objContenido.Destino)
              this.dameCaso(true*/

              this.id=response.data.objContenido.Id
              this.dameSolicitante()
              this.getCountDisponibles()

              
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en RegistraCaso',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }

        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      
    },
 getCountDisponibles(){
      let self=this
			var objRequestCount = {
				strApiKey: this.key,
				strMetodo: 'BrokerConsultasBuro',
        objParametros: {
           ApiKey: this.key
       }
			}

      this.$axios.post('/',objRequestCount,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {

					if(response.data.intEstatus == 0){
            this.count = response.data.objContenido
            this.consultasDisponibles = this.count
            //si si tiene consultas se manda el sms


            //this.generarCodigo()
            setTimeout(() =>this.generarCodigo(),2000)
            this.SmsEnvio=true

            //     this.consultar()

         

              

            

		  }else {
            /*this.$vs.notify({
              title:'Ocurrio un error en Consultas disponibles',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })*/

            this.completeInfoCirculo=false
          }
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})

    },
     MostrarMensajeCirculo(){
     this.completeInfoCirculo=true
     this.$router.push('/caso/'+this.id).catch(() => {})
    },
    filtrar(){
      var tipo = this.filtroModel
      switch(tipo) {
        case '1':
          this.items.sort((a,b) => (a.Mensualidad > b.Mensualidad) ? 1 : ((b.Mensualidad > a.Mensualidad) ? -1 : 0));
          this.filtroName= 'Mensualidad'
          this.filtroId=1
          break;
        case '2':
          this.items.sort((a,b) => (a.Cat > b.Cat) ? 1 : ((b.Cat > a.Cat) ? -1 : 0));
          this.filtroName= 'Cat'
          this.filtroId=2
          break;
        case '3':
          this.items.sort((a,b) => (a.Tasa > b.Tasa) ? 1 : ((b.Tasa > a.Tasa) ? -1 : 0));
          this.filtroName= 'Tasa'
          this.filtroId=3
          break;
        case '4':
          this.items.sort((a,b) => (a.PagoTotal > b.PagoTotal) ? 1 : ((b.PagoTotal > a.PagoTotal) ? -1 : 0));
          this.filtroName= 'Pago Total'
          this.filtroId=4
          break;
        case '5':
          this.items.sort((a,b) => (a.vctPromociones.length < b.vctPromociones.length) ? 1 : ((b.vctPromociones.length < a.vctPromociones.length) ? -1 : 0));
          this.filtroName= 'Promociones'
          this.filtroId=5
          break;
        case '6':
          this.items.sort((a,b) => (a.IngresoMinimo > b.IngresoMinimo) ? 1 : ((b.IngresoMinimo > a.IngresoMinimo) ? -1 : 0));
          this.filtroName= 'Ingreso Mínimo'
          this.filtroId=6
          break;
        case '7':
          this.items.sort((a,b) => (a.AhorroTotal < b.AhorroTotal) ? 1 : ((b.AhorroTotal < a.AhorroTotal) ? -1 : 0));
          this.filtroName= 'Ahorro Total'
          this.filtroId=7
          break;
        case '8':
          this.items.sort((a,b) => (a.MejoraMensualidad < b.MejoraMensualidad) ? 1 : ((b.MejoraMensualidad < a.MejoraMensualidad) ? -1 : 0));
          this.filtroName= 'Mejor Mensualidad'
          this.filtroId=8
          break;
      }
    },
    getDestinos(){
      let self=this
      var objRequestListaDestinos = {
        strApiKey: this.key,
        strMetodo: 'ListaDestinos',
      }
      this.$axios.post('/',objRequestListaDestinos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let destinos=response.data.objContenido
            for (var i = 0; i < destinos.length; i++) {
              if (destinos[i].Id == 1 || destinos[i].Id == 19 || destinos[i].Id == 3 || destinos[i].Id == 5 || destinos[i].Id == 2 || destinos[i].Id == 8) {
                this.destinos.push(destinos[i])
              }
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaDestinos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getProgramas(){
      let self=this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaProgramas',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.programas=response.data.objContenido
            this.todosProgramas=this.programas
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaProgramas',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getBancos(){
      let self=this
      var objRequestListaFinancieras = {
        strApiKey: this.key,
        strMetodo: 'ListaFinancieras',
        objEjecutivo: {
          EMail: this.$store.state.AppActiveUser.EMail
        }
      }
      this.$axios.post('/',objRequestListaFinancieras,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let bancos=response.data.objContenido
            for (var i = 0; i < bancos.length; i++) {
              this.bancos.push({label:bancos[i].Nombre, value: bancos[i].Id})
            }
            this.caso.bancos=this.bancosEjecutivo //cambio bancos
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaFinancieras',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getBancosEjecutivo(){
      let self=this
      var objRequestListaFinancieras = {
        strApiKey: this.key,
        strMetodo: 'ListaFinancieras',
        objEjecutivo: {
          EMail: this.$store.state.AppActiveUser.EMail
        }
      }
      this.$axios.post('/',objRequestListaFinancieras,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let bancos=response.data.objContenido
            //2,4,6,10,11
            for (var i = 0; i < bancos.length; i++) {
              this.bancosEjecutivo.push({label:bancos[i].Nombre, value: bancos[i].Id})
            }
            this.caso.bancos=this.bancosEjecutivo
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaFinancieras',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getTasas(){
      let self=this
      var objRequestListaEstados = {
        strApiKey: this.key,
        strMetodo: 'ListaPerfilesCliente',
      }
      this.$axios.post('/',objRequestListaEstados,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.tasas=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaPerfilesCliente',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    quitarFavorito(item){
      let self=this
      let objRequestFavoritoCaso = {
        strApiKey: this.key,
        strMetodo: 'NoFavorito',
        intVariante: item.Id,
        objCaso: {
         Id: this.id,
         IdBanco: item.BancoId,
         IdIon: item.IdIon
        }
      }
      this.$axios.post('/',objRequestFavoritoCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
            this.dameCaso()
            // this.$vs.notify({
            //   title:`Eliminado exitosamente ${item.Id}`,
            //   text:`${item.Producto}`,
            //   position:'top-right',
            // })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en NoFavorito',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    agregarFavorito (item) {
      let self=this
      let objRequestFavoritoCaso = {
        strApiKey: this.key,
        strMetodo: 'Favorito',
        intVariante: item.Id,
        objCaso: {
         Id: this.id,

        IdBanco: item.BancoId,
        IdIon: item.IdIon,
        intCat: item.Cat,
        ValorInmueble : this.caso.valorInmueble.replace(/,/g,""),
        MontoSolicitado: this.caso.montoSolicitado.replace(/,/g,""),
        intMensualidad: item.Mensualidad,
        intTasa: item.Tasa,
        intIngresoMinimo: item.IngresoMinimo,
        intEnganche: item.Enganche,
        intGastosIniciales: item.GastosIniciales,
        intAforo: item.Aforo,
        intPlazo: item.Plazo,
        intPagoTotal: item.PagoTotal,
        intMensualidadPromedio: item.MensualidadPromedio,
        intMensualidadInicial: item.MensualidadInicial,
        intEsquemas: item.Esquemas
        
        }
      }
      this.$axios.post('/',objRequestFavoritoCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
            this.dameCaso()
            this.$vs.notify({
              title:'Agregado exitosamente',
              text:`${item.Producto}`,
              position:'top-right',
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en NoFavorito',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    comprobarFavorito(item){

      if(item.BancoId==21 && this.caso.favoritos){
 
        for (var i = 0; i < this.caso.favoritos.length; i++) {

          if (this.caso.favoritos[i].BancoId == 21 && this.caso.favoritos[i].Mensualidad == item.Mensualidad && this.caso.favoritos[i].Tasa == item.Tasa) {           
                return false;
                

            }
        }       
      }else{
         if (this.caso.favoritos) {
            for (var i = 0; i < this.caso.favoritos.length; i++) {
                if (this.caso.favoritos[i].Id == item.Id) {            
                    return false;

                }
            }
          }

      }
      
    
      return true;
    },
    dameCaso(flag){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let cotizacion=response.data.objContenido;
            this.caso.favoritos=cotizacion.Favoritos
            this.caso.destino=cotizacion.Destino
            if (cotizacion.Programas.length) {
              this.caso.programa=cotizacion.Programas[0]
            }
            this.caso.estado=cotizacion.EstadoInmueble
            if (flag) {//alert(true)
              for (var i = 0; i < this.caso.favoritos.length; i++) {
                this.quitarFavorito(this.caso.favoritos[i])
              }
            }

            this.dameSolicitante()

          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },

    dameSolicitante() {
			let self = this
       
      if(this.emailN!=''){
        this.email=this.emailN
      }
			var objRequestDameSolicitante = {
        strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'DameSolicitante',
				objSolicitante: {
					EMail: this.email,
				},
			}
			this.$axios
				.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
            this.EstadoDom=''
						let solicitante = response.data.objContenido
         

                    
                        if(solicitante.Ingresos[0].IngresosMensuales){
                            this.ingresosSolicitante=solicitante.Ingresos[0].IngresosMensuales 
                        }
                        this.IngresoSol= this.ingresosSolicitante
                       // alert('ana'+this.IngresoSol)
                        this.telefonoCelular=solicitante.TelefonoCelular
                        this.ladaPais=solicitante.Lada
                        

                      
				

						

					} else {
						// this.$vs.notify({
						// 	title: 'Ocurrio un error en DameSolicitante',
						// 	text: response.data.strError,
						// 	color: 'danger',
						// 	position: 'top-right',
						// })
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},

   

    openFavs(){
      window.open('/imprimir/'+this.id, '_blank');
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
       // alert('apkey')
      }
    },
    dameInformacion(){
      let self=this
      let objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'MisDatos',
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let data= response.data.objContenido
            this.emailEjecutivo=data.EMail
            this.editaCaso()
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    editaCaso(){
      let self= this
      var objRequestRegistraCaso1 = {
        strApiKey: this.key,//'a13768e0b29183037ac1f3acb68871bc',
        strMetodo: 'RegistraCaso',
        objCaso: {
          Id: this.id,
          Solicitante: this.email,
          Destino : this.caso.destino,
          Broker : this.emailEjecutivo,
        }
      }
      this.$axios.post('/',objRequestRegistraCaso1,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            //alert('registrado')
            this.$vs.loading.close('#cotizacion > .con-vs-loading')
            $('#modalTabla').modal('show');
            localStorage.removeItem('caso')
            if(this.$store.state.AppActiveUser.TipoEjecutivoV3!='Inmo' ){
              $('#modalTabla').modal('show');
              $('#icono').html('').html('<svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16"><path style="color:#079DEF" d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/></svg>');
              setTimeout(() => $('#modalTabla').modal('hide'), 10000);
              $("#modalTabla").on('hidden.bs.modal', function(){
                self.$router.push('/')              
              });
              // $('#modalTabla').on('hidden.bs.modal',this.$router.push('/').catch(() => {}));
              
            }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en registraCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    validaCodigo(){
           let self=this
           this.codigoVerificacion=this.numUno+this.numDos+this.numTres+this.numCuatro
           var objGeneraCodigo={
             Codigo: this.codigoVerificacion,
             numero: this.ladaPais+this.telefonoCelular
           } 
        this.$axios.post('https://cotizador.socasesores.com/EnvioSms/ValidaSms.php',objGeneraCodigo,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
        .then(
            response => {
              //if(response.intRespuesta==0){
                 var respuestaHubspot = JSON.parse(response.data.objRespuesta)
                if(respuestaHubspot.Mensaje && respuestaHubspot.Mensaje=='Número telefónico validado con éxito'){
                  this.mensajeExito= respuestaHubspot.Mensaje
                  this.alertExito=true
                  setTimeout(() => this.alertExito = false, 5000);
                  this.mostrarBoton=true
                  this.completeInfo=true
                  this.numUno=''
                  this.numDos=''
                  this.numTres=''
                  this.numCuatro=''
                  
                    this.$router.push('/caso/'+this.id).catch(() => {})
                  this.consultar()
                }else if(respuestaHubspot.Mensaje && respuestaHubspot.Mensaje=='Código inválido o expiró'){
                  this.alertError=true
                  this.mensajeError=respuestaHubspot.Mensaje
                   setTimeout(() => this.alertError = false, 5000);
                } else if(respuestaHubspot.Message){
                  this.alertError=true
                  this.mensajeError=respuestaHubspot.Message
                   setTimeout(() => this.alertError = false, 5000);
                }else if(respuestaHubspot.Mensaje && respuestaHubspot.Mensaje == 'Error'){
                    this.alertError=true
                    this.mensajeError=respuestaHubspot.Mensaje
                    setTimeout(() => this.alertError = false, 5000);
               }else if(respuestaHubspot.Mensaje !== '' ){ 
                  this.alertError=true
                  this.mensajeError=respuestaHubspot.Mensaje
                   setTimeout(() => this.alertError = false, 5000);
              }
                
            }
        ).catch(function (error) {
            this.$vs.notify({
              title:'Ocurrio un errros en ValidaSms',
              text:error,
              color:'danger',
              position:'top-right'
            })
        })
    },

    generarCodigo(){
      this.mostrarBoton=false
         this.mensajeExito= 'En breve te llegará un código a tu teléfono, Este tiene una validez de 5 minutos.”'
        //this.alertExito=true
           //let self=this
           var objGeneraCodigo={
             metodo: 'Generar',
             numero:  this.ladaPais+this.telefonoCelular
           } 
        this.$axios.post('https://cotizador.socasesores.com/EnvioSms/GeneraSms.php',objGeneraCodigo,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
        .then(
            response => {
            var respuestaHubspot = JSON.parse(response.data.objRespuesta)
           // console.log(respuestaHubspot)
            if(respuestaHubspot.Mensaje && respuestaHubspot.Mensaje == 'Teléfono validado.'){
                  this.mensajeExito= respuestaHubspot.Mensaje
                  this.alertExito=true
                  setTimeout(() => this.alertExito = false, 5000);
                  this.mostrarBoton=true
                  this.completeInfo=true
                  this.numUno=''
                  this.numDos=''
                  this.numTres=''
                  this.numCuatro=''
                  
                  this.consultar()
                  

            }else if(respuestaHubspot.Message=='Authorization has been denied for this request.'){
                  this.alertError=true
                  this.mensajeError=respuestaHubspot.Mensaje
                   setTimeout(() => this.alertError = false, 5000);
            }else if (respuestaHubspot.Mensaje && respuestaHubspot.Mensaje == 'Mensaje SMS enviado.'){
                  this.alertExito=true
                  setTimeout(() => this.alertExito = false, 5000);
                  this.mostrarBoton=false
            }else if(respuestaHubspot.Mensaje !== '' ){ 
                   this.$vs.notify({
                    title:'Ocurrio un error en EnvioSms',
                    text:respuestaHubspot.Mensaje,
                    color:'danger',
                    position:'top-right'
                  })
                  this.alertError=true
                  this.mensajeError=respuestaHubspot.Mensaje
                   setTimeout(() => this.alertError = false, 5000);
            }
          
           
            }
        ).catch(function (error) {
           this.$vs.notify({
              title:'Ocurrio un errros en EnvioSms',
              text:error,
              color:'danger',
              position:'top-right'
            })
        })
    },
     cambiarInput(){
      //var focusedElement = document.activeElement;
      //console.log(focusedElement.id);
        if(this.numUno.length>0){
          document.getElementById('dos').focus()
        }
         if(this.numUno.length>0 && this.numDos.length>0){
          document.getElementById('tres').focus()
        }
        if(this.numUno.length>0 && this.numDos.length>0 && this.numTres.length >0){
          document.getElementById('cuatro').focus()
        }
    },
    regresarSms(){
      this.mostrarBoton=true
      this.completeInfo=true
      this.SmsEnvio=false
    },
  /*  consultar(){
      
      this.$router.push('/caso/'+this.id).catch(() => {})
      window.open('https://cotizador.socasesores.com/app/buro/'+this.email, '_blank');
    },*/

     consultar(){
      let self=this
      var objRequestConsultaBuro = {
        strApiKey: this.key,
        strMetodo: 'ConsultaBuro',
        objSolicitante: {
         EMail: this.email
        }
      }
      this.$axios.post('/',objRequestConsultaBuro,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$router.push('/caso/'+this.id).catch(() => {})
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ConsultaBuro',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },

   /* curpValida(curp) {
     
        curp = curp.toUpperCase();
        this.curp = curp
        var re =  "[A-Z][A,E,I,O,U,X][A-Z]{2}[0-9]{2}[0-1][0-9][0-3][0-9][M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]";
        var validado = curp.match(re);
      
        if (!validado){
          return false;
        }else{
          return true;
        } 
    },
    ValidaCurp(){

      
      if (this.curpValida(this.curp)) {
          document.getElementById('errorCurp').style.display='none'
          
        }else{
          document.getElementById('errorCurp').style.display='block'
         
        }
        
    },*/
    async GeneraLiga(){
      await this.getTokenSisec(); // se genera token por envio debido a expiracion
      
      let self=this
  
      var mostrar = ""
      let objRequestCotizaCaso =
            {
              AccreditedModel: {
                Name: this.nombre,
                SName: this.segundoNombre,
                LastName: this.apellidoPaterno,
                SecondLastName: this.apellidoMaterno,
                PersonalMail: this.email,
                CellPhone: this.telefono,
                CURP: this.curp,
                RFC: null,  
                CivilStatus: null,
                Nationality: null,
                Gender: null,
                Age: null,
                StudyGrade: null,
                EconomicDependents: null,
                NumberOfChildren: null,
                ChildrensAge: null,
                CP: null,
                MonetaryIncome: null,
                NumberOfCars: null,
                Activity: null,
                PhoneHome: null,
                DateBirtday: null,
                Cocredited: null,
                Weight: null,
                Height: null,
                NSS: null,
                TypeHousing: null,
                ResidenceTimeHome: null,
                ResidenceTimeTown: null,
                CompanyName: null,
                CompanyAddress: null,
                Position: null,
                TypeCompany: null,
                WorkingTime: null,
              },
              ReferencesModel: {
                Name: null,
                SName: null,
                LastName: null,
                SecondLastName: null,
                Phone: null,
                Relationship: null,
                TimeRelationship: null
              },
              CreditRequestModel: {
                TypeCredit: null,
                HomeValue: null,
                CreditAmount: null,
                Plazo: null
              },
              BrokerEmployeeModel: {
                Id: this.SocGuid,
                BrokerId: this.BrokerId,
                EmployeeName: null,
                EmployeeEmail: null,
                Origin: "Cotizador soc"
                
              },
              IsQuickQuote:true,
            }

      this.$axios.post('https://apivalidacionriesgos.sisec.mx/api/External/CreateAccredited',objRequestCotizaCaso,{headers : this.header})
            .then(
              response => {
                this.$vs.loading({
                    container: '#cotizacion',
                    scale: 0.6
                  }) 
                document.getElementById('btnGuardar').style.display='none' 
                this.registrarSolicitante()    
                this.IdLiga = response.data.AccreditedId           
                this.EnviaCorreo()
                
              }
            ).catch(function (error) {
              if(error.response.data.Message == "The CellPhone already exists"){
                mostrar = "El telefono ya se encuentra registrado intente con otro"
              }else if(error.response.data.Message == "The email already exists"){
                mostrar = "El correo elecronico ya se encuentra registrado intente con otro"
              }else{
                mostrar = error
              }
              
              self.$vs.notify({
                title:'Ocurrio un error en SISEC',
                text:mostrar,
                color:'danger',
                position:'top-right'
              })
            })
           
    },
    DameCredenciales(){
      let self=this
      let objRequestDameCredenciales = {
        strApiKey: this.key,
        strMetodo: 'DameCredenciales',
        objBroker: {
          ApiKey: this.key
        }
      }
      this.$axios.post('/',objRequestDameCredenciales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          this.SocGuid = response.data.objContenido.SocGuid
          this.BrokerId = response.data.objContenido.BrokerId
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    EnviaCorreo(){
      let self=this
      let objRequestEnviaLigaCorreo = {
        strApiKey: this.key,
        strMetodo: 'EnviaLigaCorreo',
        objDatos: {
          Nombre: this.nombre,
          Id: this.IdLiga,
          Correo: this.email
        }
      }
    this.$axios.post('/',objRequestEnviaLigaCorreo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      //axios.post('https://cotizador.socasesores.com//apipro/',objRequestEnviaLigaCorreo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          //alert("enviado")
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
  }
}
</script>

<style lang="css" scoped>
.vs-dialog-primary .vs-dialog .vs-dialog-header{
  background: #079DEF;
  color: #FFF !important;
      border-radius: 5px 5px 0px 0px;
}
.con-vs-dialog .vs-dialog header .dialog-title,
.con-vs-dialog .vs-dialog header .vs-dialog-cancel{
  color: #FFF;
}
[dir] .con-vs-dialog .vs-dialog .vs-dialog-text {
  background-color: #f0f0f0;
}
.vs-component.con-vs-checkbox.vs-checkbox-primary.vs-checkbox-default{
  display: flex !important;
    justify-content: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
.filtros {
	display: -webkit-box !important;
	flex-wrap: inherit !important;
}
.favoritos {
	border: none !important;
	background: transparent !important;
}
th {
	font-size: 14px;
}
.w2-full {
	padding-top: 0px !important;
	width: 100%;
}
.vx-card.arriba {
	margin-top: -860px;
	padding-top: 15px;
	z-index: 777;
	min-height: 900px;
}
.btn-fav {
	float: right !important;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	margin-right: 30px !important;
}

.router-view {
	padding-top: 25rem !important;
}
.btn-empty {
    padding: 0.75rem 2rem;
}

@media screen and (max-width: 915px) {
	.btn-fav {
		align-content: center;
		justify-content: center;
		display: flex;
		background-color: #fff;
		color: #40516f;
		border-radius: 5px;
		border: #e0e0e0 solid 1px;
		min-width: 100%;
		max-width: 100%;
		margin-right: 0px !important;
		margin-left: auto;
		float: none !important;
	}

	.w2-full {
		width: auto;
	}
}

.selectExample{
  width: 150%;}

.confirmacion-phone{
    background: #F1F2F7 0% 0% no-repeat padding-box;
    border: 1px solid #00000033;
    border-radius: 4px;
    padding: 4rem 1rem 2rem 1rem;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}
.confirmacion-phone input{
    max-width: 40px;
    margin: 0 auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E0E0E0;
}
.confirmacion-phone p{
    color: #212529 !important;
    font-weight: bold !important;
    visibility: visible; 
    opacity: 1;
    display: block;
    font-size: 14px !important;
    background-color: unset;
    text-align: center;
}
.confirmacion-phone a{
    color: #829494;
}
.confirmacion-phone a.blue{
    color: #2AB5DF;
}
.confirmacion-phone{
    position: relative;
}
.close{
    position: absolute;
    right: 10px;
    top: 5px;
}
.btn-back{
    background-color: transparent !important;
    color: #6c757d;
    font-size: 17px !important;
    width: 275px !important;
    height: 44px;
    border: none;

}
.ocultarMensaje{
  display: none !important;
}
.alert {
    max-width: 300px;
    bottom: 63px;
    right: 3.2rem;
    position: absolute;
    padding: 0.75rem 1.25rem;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.vs-component.con-vs-dialog.vs-dialog-primary {
    top: 120px !important;
}
</style>