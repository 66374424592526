<template lang="html">
	<div class="">
		<div class="">
			<div class="">
				<div class="row w-full">
					<div class="col-md-3">
						<span class="span-placeholder">Código postal</span>
						<input type="text" class="vs-input" v-model="codigoPostal" @blur="getColoniaMunucipio" name="cp" minlength="5" maxlength="5" v-validate="'required|min:5|max:5'" v-on:keypress="isNumber($event)"  />

						<span class="text-danger span-placeholder" v-show="errors.has('cp')">El campo es obligatorio y debe tener 5 caracteres</span>
					</div>

					<div class="col-md-3">
            <span class="span-placeholder">Municipio</span>
            <input class="vs-input" v-validate="'required'"  v-model="this.municipio" name="municipio" disabled/>
			<span class="text-danger span-placeholder" v-show="errors.has('municipio')">El campo es obligatorio</span>
          </div>
		  <div class="col-md-1"></div>

          <div class="col-md-5">
            <span class="span-placeholder" for="colonia">Colonia</span>
            <select id="colonia" v-validate="'required'"  v-model="colonia" name="colonia" class="vs-input" :disabled="disabled">
							<option value="" selected>Selecciona una colonia</option>
              <option :value='colonia' v-for="colonia in ColoniasCP" :key="">{{colonia}}</option>
            </select>
			<span class="text-danger span-placeholder" v-show="errors.has('colonia')">El campo es obligatorio</span>
          </div>
				</div>
				<div class="row w-full">
					<div class="col-md-6">
						<span class="span-placeholder">Calle</span>
						<input class="vs-input" v-validate="'required'"  v-model="calle"   minlength="3"  maxlength="30" name="calle" />
						<span class="text-danger span-placeholder" v-show="errors.has('calle')">El campo es obligatorio</span>
					</div>
					<div class="col-md-1"></div>

					<div class="col-md-3">
						<span class="span-placeholder">Número exterior</span>
						<input class="vs-input" v-model="numeroExterior"   minlength="1"   maxlength="20" name="name" />
					</div>
					<div class="col-md-2">
						<span class="span-placeholder">Número Interior</span>
						<input class="vs-input" v-model="numeroInterior"   minlength="1"  maxlength="20" name="username" />
					</div>
				</div>
				<div class="row w-full">
					<div class="col-md-6">
						<span class="span-placeholder">Ciudad</span>
						<input class="vs-input" v-model="ciudad" v-validate="'required'"   maxlength="30" name="ciudad" />
						<span class="text-danger span-placeholder" v-show="errors.has('ciudad')">El campo es obligatorio</span>
					</div>
					<div class="col-md-1"></div>

					<div class="col-md-5">
						<span class="span-placeholder" for="estado">Estado</span>
						<select name="estado" id="estado" v-validate="'required'"  v-model="estado" class="vs-input">
							<option :value="tipo.Id" v-for="tipo in estados" :key="">{{ tipo.Nombre }}</option>
						</select>
						<span class="text-danger span-placeholder" v-show="errors.has('estado')">El campo es obligatorio</span>
					</div>
				</div>

				<br /><br />

				<div class="row w-full">
					<div class="col-md-8"></div>
					<div class="col-md-2">
					 
					   <button class="btn btn-empty"  @click="RegresarPersonales()"  ><h5>Regresar</h5></button>
					</div>
					
					<div class="col-md-2">
					     
						<button class="btn btn-second" @click="agregaConyuge()" :disabled="!validateForm">Siguiente</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
export default {
	components: {
		'v-select': vSelect,
	},
	props: {
		emailN: {
			type: String,
			required: false,
		},
		telN: {
			type: String,
			required: false,
		},
		IdN: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			maskRfc: ['AAAA999999&&&'],
			conyuge: {
				participaCredito: false,
				email: '',
				nombre: '',
				apellidoPaterno: '',
				apellidoMaterno: '',
				curp: '',
				rfc: '',
				esConyugue: true,
				seraCoPropietario: false,
				consolidaIngresos: false,
				parentesco: 1,
			},
			coacreditado: {
				email: '',
				email2: '',
				email3: '', //existe coacreditado
				esConyugue: false,
				seraCoPropietario: true,
				consolidaIngresos: true,
				parentesco: '',
				participaCredito: true,
			},
			 ingreso: {
				ingresoId: '',
				ingresoNeto: '',
				ingresoMensual: '',
				tipoEmpleo: '',
				empresa: '',
				fuente: '',
				puesto: '',
				giro: '',
				tipoContrato: '',
				impuestos: false,
				fechaInicio: '',
				domicilio: '',
				colonia: '',
				numeroExterior: '',
				numeroInterior: '',
				ciudad: '',
				municipio: '',
				codigoPostal: '',
				telefono: '',
				area: '',
				sector: '',
				profesion: '',
				comprobanteIngresos: '',
				estado: '',
				pais: '',
				jefe: '',
			},
			id: '',
			key: '',
			email: '',
			nombre: '',
			apellidoPaterno: '',
			apellidoMaterno: '',
			telefono: '',
			fechaNacimiento: '',
			ingresosSinImpuestos: '',
			IngresosConImpuestos: '',
			estadoCivil: '',
			regimenMatrimonial: '',
			estadosCiviles: [],
			rfc: '',
			curp: '',
			sexo: '',
			calle: '',
			numeroInterior: '',
			numeroExterior: '',
			colonia: '',
			municipio: '',
			ciudad: '',
			nacionalidad: '',
			estado: '',
			codigoPostal: '',
			estados: [],
			ColoniasCP: [],
			MunicipiosCP: [],
			states: ['AGUASCALIENTES', 'BAJA CALIFORNIA NORTE', 'BAJA CALIFORNIA SUR', 'CAMPECHE', 'CHIAPAS', 'CHIHUAHUA', 'COAHUILA', 'COLIMA', 'CIUDAD DE MEXICO', 'DURANGO', 'ESTADO DE MÉXICO', 'GUANAJUATO', 'GUERRERO', 'HIDALGO', 'JALISCO', 'MICHOACAN', 'MORELOS', 'NAYARIT', 'NUEVO LEON', 'OAXACA', 'PUEBLA', 'QUERETARO', 'QUINTANA ROO', 'SAN LUIS POTOSI', 'SINALOA', 'SONORA', 'TABASCO', 'TAMAULIPAS', 'TLAXCALA', 'VERACRUZ', 'YUCATAN', 'ZACATECAS'],
			parentescos: [],
			regimenes: [],
			nacionalidades: [
				{ label: 'MEXICANA', value: 'MEXICANO' },
				{ label: 'EXTRANJERA', value: 'EXTRANJERO' },
			],
			codigoSeleccionado: '',
			codigoPaises: [],
			emailCasoNuevo: { bandera: '', Email: '', tel: '', Id: '' },
      disabled: true
		}
	},
	computed: {
		validate: function () {
			if (this.estadoCivil == 1 || this.estadoCivil == 4) {
				return this.conyuge.email != '' && this.conyuge.nombre != '' && this.conyuge.apellidoPaterno != '' && this.conyuge.apellidoMaterno != ''
			} else {
				return true
			}
		},
		validate2: function () {
			return this.coacreditado.email != '' && this.coacreditado.parentesco != ''
		},
		validateForm(){
		  return this.codigoPostal != '' && this.calle != '' && this.ciudad != '' && this.estado != ''
		},
	},
	watch: {
		estadoCivil: function () {
			if (this.estadoCivil == 2 || this.estadoCivil == 3 || this.estadoCivil == 5 || this.estadoCivil == 6) {
				this.regimenMatrimonial = ''
				this.conyuge.participaCredito = false
				this.conyuge.email = ''
				this.conyuge.nombre = ''
				this.conyuge.apellidoPaterno = ''
				this.conyuge.apellidoMaterno = ''
				this.conyuge.curp = ''
				this.conyuge.rfc = ''
				this.conyuge.esConyugue = true
				this.conyuge.seraCoPropietario = true
				this.conyuge.consolidaIngresos = true
				this.conyuge.parentesco = 1
				this.conyuge.fechaNacimiento = ''
				this.conyuge.sexo = ''
				this.conyuge.nacionalidad = ''
				this.conyuge.paisRecidencia = ''
			}
		},
	},
	mounted() {
		if (!this.$route.params.id) {

			if(this.IdN!=''){
			  this.id = this.IdN
			  this.email= this.emailN
			//  alert('id'+this.id)
			  //this.dameCaso()
			  this.dameSolicitante()
			   

			}

			this.getApikey()
			this.getEstados()
			this.getEstadosCiviles()
			this.getListaRegimenes()
			this.getListaParentescos()
			this.getListaCodigoPaises() //sms
		} else {
			this.id = this.$route.params.id
			this.getApikey()
			this.dameCaso()
			this.getEstados()
			this.getEstadosCiviles()
			this.getListaRegimenes()
			this.getListaParentescos()
			this.getListaCodigoPaises() //sms
		}
	},
	methods: {
		/*solorfc: function(event) {
      var regex = new RegExp("([A-Z,Ñ,&,a-z,ñ]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z,a-z|\d]{3})+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
          event.preventDefault();
          return false;
        }
      /*const regex = /^([A-Z,Ñ,&,a-z,ñ]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z,a-z|\d]{3})$/
      // const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const matches = regex.test(this.datosPersonales.rfc);
      if(matches){
        this.rfcValido=false
      }else{
        this.rfcValido=true
      }*/

		/* },
    alfanumericos: function(event) {
        var regex = new RegExp("^[ña-zÑA-Z0-9 ]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
          event.preventDefault();
          return false;
        }
    },*/
		soloLetras: function (event) {
			var regex = new RegExp('^[áéíóúüña-zÑÁÉÍÓÚA-Z ]+$')
			var key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
			if (!regex.test(key)) {
				event.preventDefault()
				return false
			}
		},
		isNumber: function (evt) {
			evt = evt ? evt : window.event
			var charCode = evt.which ? evt.which : evt.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
				evt.preventDefault()
			} else {
				return true
			}
		},
		actualizaCodigo() {
			this.codigoPais = this.codigoSeleccionado
		},
		getListaCodigoPaises() {
			var objRequestListaPais = {
				strApiKey: this.key,
				strMetodo: 'ListaPaisLada',
			}
			this.$axios
				.post('/', objRequestListaPais, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.codigoPaises = response.data.objContenido
						//console.log(this.codigoPaises)
					}
				})
				.catch(function (error) {
					this.makeToast(error)
				})
		},
		dameCoacreditadoRegistrado() {
			let self = this
			var objRequestDameSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'DameSolicitante',
				objSolicitante: {
					EMail: this.coacreditado.email,
				},
			}
			this.$axios
				.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.coacreditado.email3 = this.coacreditado.email
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		registraCoacreditado() {
			let self = this
			if (this.coacreditado.email == this.conyuge.email) {
				let objRequestRegistraSolicitante = {
					strFolioCaso: this.id,
					strApiKey: this.key,
					strMetodo: 'RegistraSolicitante',
					objSolicitante: {
						EMail: this.conyuge.email,
						Nombres: this.conyuge.nombre,
						ApellidoPaterno: this.conyuge.apellidoPaterno,
						ApellidoMaterno: this.conyuge.apellidoMaterno,
						TelefonoCelular: '',
						FechaNacimiento: '',
					},
				}
				this.$axios
					.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
					.then((response) => {
						if (response.data.intEstatus == 0) {
							this.agregarCoacreditado()
						} else {
							this.$vs.notify({
								title: 'Ocurrio un error en RegistraSolicitante',
								text: response.data.strError,
								color: 'danger',
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						self.$vs.notify({
							title: 'Ocurrio un error de sistema',
							text: error,
							color: 'danger',
							position: 'top-right',
						})
					})
			} else if (this.coacreditado.email3 != '') {
				this.agregarCoacreditado()
			} else {
				if (this.coacreditado.email2) {
					this.agregarCoacreditado()
				} else {
					let objRequestRegistraSolicitante = {
						strFolioCaso: this.id,
						strApiKey: this.key,
						strMetodo: 'RegistraSolicitante',
						objSolicitante: {
							EMail: this.coacreditado.email,
							Nombres: '',
							ApellidoPaterno: '',
							ApellidoMaterno: '',
							TelefonoCelular: '',
							FechaNacimiento: '',
						},
					}
					this.$axios
						.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
						.then((response) => {
							if (response.data.intEstatus == 0) {
								this.agregarCoacreditado()
							} else {
								this.$vs.notify({
									title: 'Ocurrio un error en RegistraSolicitante',
									text: response.data.strError,
									color: 'danger',
									position: 'top-right',
								})
							}
						})
						.catch(function (error) {
							self.$vs.notify({
								title: 'Ocurrio un error de sistema',
								text: error,
								color: 'danger',
								position: 'top-right',
							})
						})
				}
			}
		},
		agregarCoacreditado() {
			let self = this
			var objRequestRegistraCoacreditado = {
				strApiKey: this.key,
				strMetodo: 'RegistraCoacreditado',
				objCaso: {
					Id: this.id,
					EMail: this.coacreditado.email,
					DatosCoacreditado: {
						EsConyuge: this.coacreditado.esConyugue,
						SeraCoPropietario: this.coacreditado.seraCoPropietario,
						ConsolidaIngresos: this.coacreditado.consolidaIngresos,
						Parentesco: this.coacreditado.parentesco,
						ParticipaCredito: this.coacreditado.participaCredito,
					},
				},
			}
			this.$axios
				.post('/', objRequestRegistraCoacreditado, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Datos guardados exitosamente`,
							position: 'top-right',
						})
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraCoacreditado',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		agregaConyugeAsCoacreditado() {
			let self = this
			var objRequestRegistraCoacreditado = {
				strApiKey: this.key,
				strMetodo: 'RegistraCoacreditado',
				objCaso: {
					Id: this.id,
					EMail: this.conyuge.email,
					DatosCoacreditado: {
						EsConyuge: this.conyuge.esConyugue,
						SeraCoPropietario: this.conyuge.seraCoPropietario,
						ConsolidaIngresos: this.conyuge.consolidaIngresos,
						Parentesco: this.conyuge.parentesco,
						ParticipaCredito: this.conyuge.participaCredito,
					},
				},
			}
			this.$axios
				.post('/', objRequestRegistraCoacreditado, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Datos guardados exitosamente`,
							position: 'top-right',
						})
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraCoacreditado',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		RegresarPersonales(){


			this.emailCasoNuevo.bandera= 'PersonalesRegresar'
			this.emailCasoNuevo.Email= this.email
			this.emailCasoNuevo.tel= this.telefono
			this.emailCasoNuevo.Id= this.Id

					
			this.$emit("bandera",  this.emailCasoNuevo)

		},
		agregaConyuge() {
			this.emailCasoNuevo.bandera = 'Domicilio'
			this.emailCasoNuevo.Email = this.emailN
			this.emailCasoNuevo.tel = this.telN
            this.emailCasoNuevo.Id= this.IdN

			this.$emit('bandera', this.emailCasoNuevo)
		 // alert( this.emailCasoNuevo.Id)

			if (this.conyuge.email != '') {
				let self = this

				let sexoConyuge
				if (this.conyuge.sexo == 'H') {
					sexoConyuge = 1
				} else {
					sexoConyuge = 2
				}

				let objRequestRegistraSolicitante = {
					strFolioCaso: this.id,
					strApiKey: this.key,
					strMetodo: 'RegistraSolicitante',
					objSolicitante: {
						EMail: this.emailN,
						Nombres: this.conyuge.nombre,
						ApellidoPaterno: this.conyuge.apellidoPaterno,
						ApellidoMaterno: this.conyuge.apellidoMaterno,
						TelefonoCelular: '',
						FechaNacimiento: this.conyuge.fechaNacimiento,
						Rfc: this.conyuge.rfc,
						Curp: this.conyuge.curp,
						Sexo: sexoConyuge,
						Nacionalidad: this.conyuge.nacionalidad,
						PaisRecidencia: this.conyuge.paisRecidencia,
					},
				}
				this.$axios
					.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
					.then((response) => {
						if (response.data.intEstatus == 0) {
							if (this.conyuge.participaCredito) {
								this.agregaConyugeAsCoacreditado()
							}
							this.actualizarInfo()
						} else {
							this.$vs.notify({
								title: 'Ocurrio un error en RegistraSolicitante',
								text: response.data.strError,
								color: 'danger',
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						self.$vs.notify({
							title: 'Ocurrio un error de sistema',
							text: error,
							color: 'danger',
							position: 'top-right',
						})
					})
			} else {
				this.actualizarInfo()
			}
		},
		actualizarInfo() {
			let self = this
			let sexo
			if (this.sexo == 'H') {
				sexo = 1
			} else {
				sexo = 2
			}

			this.emailCasoNuevo.bandera = 'Domicilio'
			this.emailCasoNuevo.Email = this.emailN
			this.emailCasoNuevo.tel = this.telN
            this.emailCasoNuevo.Id= this.IdN
			
			this.$emit('bandera', this.emailCasoNuevo)

			let objRequestRegistraSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraSolicitante',
				objSolicitante: {
					EMail: this.emailN,
					TelefonoCelular: this.telN,
					Calle: this.calle,
					NumeroExterior: this.numeroExterior,
					NumeroInterior: this.numeroInterior,
					Colonia: this.colonia,
					Municipio: this.municipio,
					Ciudad: this.ciudad,
					Estado: this.estado,
					CodigoPostal: this.codigoPostal,
					Nacionalidad: this.nacionalidad,
					EstadoCivil: this.estadoCivil,
					RegimenMatrimonial: this.regimenMatrimonial,
					Conyuge: this.conyuge.email,
				},
			}
			this.$validator.validateAll().then((result) => {
				if (result) {
					this.$axios
						.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
						.then((response) => {
							if (response.data.intEstatus == 0) {
								/*this.$vs.notify({
									title: `Datos guardados exitosamente`,
									position: 'top-right',
								})*/
								this.email=this.emailN
								 this.dameSolicitante()
							} else {
								this.$vs.notify({
									title: 'Ocurrio un error en RegistraSolicitante',
									text: response.data.strError,
									color: 'danger',
									position: 'top-right',
								})
							}
						})
						.catch(function (error) {
							self.$vs.notify({
								title: 'Ocurrio un error de sistema',
								text: error,
								color: 'danger',
								position: 'top-right',
							})
						})
				} else {
					this.$vs.notify({
						title: 'Ocurrio un error',
						text: 'Datos incompletos',
						color: 'danger',
						position: 'top-right',
					})
				}
			})
		},
		dameCaso() {
			let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'DameCaso',
				objCaso: {
					Id: this.id,
				},
			}
			this.$axios
				.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.email = response.data.objContenido.Solicitante
						let coacreditado = response.data.objContenido.Coacreditado
						if (coacreditado.length) {
							if (coacreditado[0].EsConyuge && coacreditado[0].ParticipaCredito) {
								this.conyuge.esConyugue = coacreditado[0].EsConyuge
								this.conyuge.email = coacreditado[0].EMail
								this.conyuge.seraCoPropietario = coacreditado[0].SeraCoPropietario
								this.conyuge.consolidaIngresos = coacreditado[0].ConsolidaIngresos
								this.conyuge.parentesco = coacreditado[0].Parentesco
								this.conyuge.participaCredito = coacreditado[0].ParticipaCredito
							} else {
								this.coacreditado.esConyugue = coacreditado[0].EsConyuge
								this.coacreditado.email2 = coacreditado[0].EMail
								this.coacreditado.email = coacreditado[0].EMail
								this.coacreditado.seraCoPropietario = coacreditado[0].SeraCoPropietario
								this.coacreditado.consolidaIngresos = coacreditado[0].ConsolidaIngresos
								this.coacreditado.parentesco = coacreditado[0].Parentesco
								this.coacreditado.participaCredito = coacreditado[0].ParticipaCredito
							}
						}
						this.dameSolicitante()
						this.email = response.data.objContenido.Solicitante

					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameCaso 2',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		dameConyuge(email) {
			let self = this
			var objRequestDameSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'DameSolicitante',
				objSolicitante: {
					EMail: email,
				},
			}
			this.$axios
				.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						let conyuge = response.data.objContenido
						this.conyuge.nombre = conyuge.Nombres
						this.conyuge.email = conyuge.EMail
						this.conyuge.apellidoPaterno = conyuge.ApellidoPaterno
						this.conyuge.apellidoMaterno = conyuge.ApellidoMaterno
						this.conyuge.rfc = conyuge.Rfc
						this.conyuge.curp = conyuge.Curp
						this.conyuge.fechaNacimiento = conyuge.FechaNacimiento
						if (conyuge.Sexo == 1) {
							this.conyuge.sexo = 'H'
						} else if (conyuge.Sexo == 2) {
							this.conyuge.sexo = 'M'
						}
						this.conyuge.nacionalidad = conyuge.Nacionalidad
						this.conyuge.paisRecidencia = conyuge.PaisRecidencia
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameSolicitante',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		dameSolicitante() {
			let self = this
			var objRequestDameSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'DameSolicitante',
				objSolicitante: {
					EMail: this.email,
				},
			}
			this.$axios
				.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						let solicitante = response.data.objContenido
						this.nombre = solicitante.Nombres
						this.apellidoPaterno = solicitante.ApellidoPaterno
						this.apellidoMaterno = solicitante.ApellidoMaterno
						this.telefono = solicitante.TelefonoCelular.replace(/\s+/g, '').replace(/-/g, '')
						this.fechaNacimiento = solicitante.FechaNacimiento
						this.rfc = solicitante.Rfc
						if (solicitante.Sexo == 1) {
							this.sexo = 'H'
						} else if (solicitante.Sexo == 2) {
							this.sexo = 'M'
						}

						this.calle = solicitante.Calle
						//alert('calle'+this.calle )
						this.numeroExterior = solicitante.NumeroExterior
						this.numeroInterior = solicitante.NumeroInterior
						this.colonia = solicitante.Colonia
						this.ciudad = solicitante.Ciudad
						this.municipio = solicitante.Municipio
						this.curp = solicitante.Curp
						this.codigoPostal = solicitante.CodigoPostal
						this.estado = solicitante.Estado
						this.estadoCivil = solicitante.EstadoCivil
						this.regimenMatrimonial = solicitante.RegimenMatrimonial
						this.nacionalidad = solicitante.Nacionalidad
						if (solicitante.Conyuge != null && (this.estadoCivil == 1 || this.estadoCivil == 4)) {
							this.dameConyuge(solicitante.Conyuge)
						}
						this.codigoSeleccionado = solicitante.Lada
						
						this.ingreso.ingresoMensual= solicitante.Ingresos[0].IngresosMensuales
						this.ingreso.fechaInicio= solicitante.Ingresos[0].FechaInicio
						this.ingreso.tipoEmpleo= solicitante.Ingresos[0].TipoEmpleoId
						this.ingreso.comprobanteIngresos= solicitante.Ingresos[0].ComprobanteIngresosId


						 this.getColoniaMunucipioAct(solicitante.Colonia)
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameSolicitante',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getApikey() {
			if (!JSON.parse(localStorage.getItem('userInfo')).ApiKey) {
				this.$router.push('/login')
			} else {
				this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey
			}
		},
		getEstadosCiviles() {
			let self = this
			var objRequestListaEstadosCiviles = {
				strApiKey: this.key,
				strMetodo: 'ListaEstadosCiviles',
			}
			this.$axios
				.post('/', objRequestListaEstadosCiviles, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.estadosCiviles = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaEstadosCiviles',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getEstados() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaEstados',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.estados = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaEstados',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getStateName(id) {
			return this.states[id - 1]
		},
		getStateNumber(stateName) {
			for (var i = 0; i < this.states.length; i++) {
				if (stateName == this.states[i]) {
					return i + 1
				}
			}
		},
		getListaParentescos() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaParentescos',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.parentescos = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaParentescos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getListaRegimenes() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaRegimenesMatrimoniales',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.regimenes = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaRegimenesMatrimoniales',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		isNumber: function (evt) {
			evt = evt ? evt : window.event
			var charCode = evt.which ? evt.which : evt.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
				evt.preventDefault()
			} else {
				return true
			}
		},
		 getColoniaMunucipioAct(e) {

      const cp = e
	  //alert(cp)
	  this.ColoniasCP = []
	  this.ColoniasCP = [cp]



    },
    async getColoniaMunucipio(e) {

      const cp = e.target.value

      if ( cp.length !== 5 ) {
        return this.disabled = true
      }

      const dataAuthentication = await this.getTokenSisec()

			if ( !dataAuthentication ) return

			const token = dataAuthentication.objRespuesta.token
      const dataInfoCP = await this.getInformationCP(token)

			if ( !dataInfoCP ) {
				this.ColoniasCP = []
				this.municipio = ''
				return
			}

			const colonias = dataInfoCP.map(x => x.colonia)
      this.disabled = false
			this.ColoniasCP = colonias
			this.municipio = dataInfoCP[0].municipio

    },
    async getTokenSisec() {
      const URL_Method = 'https://cotizador.socasesores.com/consultaSisec/getTokenSisec.php'

      const { data, status } = await this.$axios
				.post(URL_Method,{headers : {'Content-Type':'application/json; charset=UTF-8'}})

      if ( status !== 200 ) {

        this.$vs.notify({
          title: 'Ocurrio un error en getTokenSisec',
          text: data.strError,
          color: 'danger',
          position: 'top-right',
        })

        return false

      }

      if ( data.intRespuesta ) {

        this.$vs.notify({
          title: 'Ocurrio un error en Metodo getTokenSisec',
          text: data.strError,
          color: 'danger',
          position: 'top-right',
        })

        return false

      }

      if ( !data.objRespuesta.status ) {

        this.$vs.notify({
          title: 'Ocurrio un error en API getTokenSisec',
          text: data.strError,
          color: 'danger',
          position: 'top-right',
        })

        return false

      }

      return data

    },
    async getInformationCP(token) {

      const jsonCP = {
				token,
        CodigoPostal: this.codigoPostal
      }

      const URL_Method = 'https://cotizador.socasesores.com/consultaSisec/getInfoCP.php'

      const { status, data } = await this.$axios
				.post(URL_Method,jsonCP,{headers : {'Content-Type':'application/json; charset=UTF-8'}})

			if ( status !== 200 ) {

        this.$vs.notify({
          title: 'Ocurrio un error en getInformationCP',
          text: data.strError,
          color: 'danger',
          position: 'top-right',
        })

        return false

      }

			if ( data.intRespuesta ) {

        this.$vs.notify({
          title: 'Ocurrio un error en Metodo getInformationCP',
          text: data.strError,
          color: 'danger',
          position: 'top-right',
        })

        return false

      }

			if ( !data.objRespuesta.length ) {

				this.$vs.notify({
          title: data.objRespuesta.message,
          text: data.strError,
          color: 'danger',
          position: 'top-right',
        })

        return false

			}

      return data.objRespuesta

    }
	},
}
</script>

<style lang="css">
.red{
  color: red;
}
.btn-empty {
    padding: 0.75rem 2rem;
}
</style>