<template lang="html">
	<div class="">
		<div class="">
			<div class="">
				<!--<vx-card :title="'Solicitante: '+email" class="">-->
				<vx-card>

        

           <vs-prompt title="Aviso"  class="export-options prompt-modal " :active="!completeInfo"  @close="MostrarMensajeInicial()" style="text-align:center;"> <!--si es diferente de true osea falso-->
              Recuerda que el servicio de Precalificación SOC
                hace uso de las consultas de Circulo de Crédito
                que tenga disponible tu empresa, además de que
                necesitas tener activada la facultad para
                realizar estas consultas.
                  <br><br><br>
                  <div class="row" style="text-align:center;">
                 <vs-checkbox v-model="mensajeInicial" :vs-value="true"  style="text-align:center;">No volver a mostrar</vs-checkbox>
                 </div>


              <div class="row w-full" style="display:flex; justify-content:center;">
                <div class="col-md-3">
                  <button class="btn btn-second" @click="MostrarMensajeInicial()">Aceptar</button>
                </div>
              </div>




						</vs-prompt>

					<div class="row w-full">
						<div class="col-md-3">
							<span class="span-placeholder">Nombre</span>
							<input class="vs-input" v-validate="'required|alpha_spaces'" v-model="nombre"  minlength="3" maxlength="30" v-on:keypress="soloLetras($event)" name="nombres" />
							<span class="text-danger span-placeholder" v-show="errors.has('nombres')">Campo requerido</span>
						</div>
                        <div class="col-md-3">
							<span class="span-placeholder">Segundo Nombre</span>
							<input class="vs-input" v-model="snombre" minlength="0" maxlength="30" v-on:keypress="soloLetras($event)" name="Snombres" />
							
						</div>
						<div class="col-md-3">
							<span class="span-placeholder">Apellido Paterno</span>
							<input class="vs-input" v-validate="'required|alpha_spaces'" minlength="3" maxlength="30" v-model="apellidoPaterno" v-on:keypress="soloLetras($event)" name="apellidoPaterno" />
							<span class="text-danger span-placeholder" v-show="errors.has('apellidoPaterno')">Campo requerido</span>
						</div>
						<div class="col-md-3">
							<span class="span-placeholder">Apellido Materno</span>
							<input class="vs-input" v-model="apellidoMaterno" minlength="3" maxlength="30" v-on:keypress="soloLetras($event)" name="apellidoMaterno" />
						
						</div>
					</div>

					<div class="row w-full">
						<div class="col-md-3">
							<div class="form-group">
								<span class="formGroupExampleInput span-placeholder">Código país</span>
								<select name="cars" id="codigo" v-model="codigoSeleccionado" class="vs-input" style="border: 1px solid rgba(0, 0, 0, 0.2)" aria-placeholder="Seleccionar una opción" @change="actualizaCodigo()">
									<option :value="tipo.Lada" v-for="tipo in codigoPaises" :key="tipo.Id">{{ tipo.Pais }}</option>
								</select>
							</div>
						</div>

						<div class="col-md-3">
							<span class="formGroupExampleInput span-placeholder">Teléfono celular</span>
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text" id="basic-addon1">{{ codigoSeleccionado }}</span>
								</div>
								<input type="text" class="vs-input" v-validate="'required|min:10|max:10'"  name="telefono"  v-model="telefono" maxlength="10" placeholder="Número a 10 dígitos" aria-label="Username" aria-describedby="basic-addon1" v-on:keypress="isNumber($event)" />
                <br>
                <span class="text-danger span-placeholder" v-show="errors.has('telefono')">Campo requerido</span>
							</div>
						</div>

						<div class="col-md-3">
							<span class="span-placeholder">Correo electronico</span>
							<input class="vs-input" v-validate="'required|min:3|max:30'"  minlength="3" maxlength="30" v-model="email" name="email" />
               <span class="text-danger span-placeholder" v-show="errors.has('email')">Campo requerido</span>
						</div>

                        <div class="col-md-3">
							<span class="span-placeholder">Fecha de nacimiento</span>
							<input class="vs-input" type="date" v-validate="'required'"   v-model="fechaNacimiento" name="fechaNacimiento" />
							<span class="text-danger span-placeholder" v-show="errors.has('fechaNacimiento')">Campo requerido</span>
						</div>
					</div>

					<div class="row w-full">

                    	<div class="col-md-3">
							<span class="span-placeholder" for="sexo">Genero</span>
							<div class="col-md-12 mt-2 alinear">
								<div class="mr-4 alinear">
									<div class="span-placeholder mr-2">Hombre</div>
									<vs-radio v-model="sexo"  vs-name="sexo" name="sexo"  v-validate="'required'"  vs-value="H"></vs-radio>
								</div>
								<div class="ml-4 alinear">
									<div class="span-placeholder mr-2">Mujer</div>
									<vs-radio v-model="sexo"  vs-name="sexo" name="sexo" v-validate="'required'"  vs-value="M"></vs-radio>
                  
								</div>
							</div>
              <span class="text-danger span-placeholder" v-show="errors.has('sexo')">Campo requerido</span>
						</div>

						<div class="col-md-3">
							<span class="span-placeholder">CURP</span>
							<input class="vs-input" v-model="curp" name="curp" v-validate="'required|min:18|max:18'" v-mask="'AAAA######AAAAAANN'" value="" onkeyup="javascript:this.value=this.value.toUpperCase();" />
							<span class="text-danger span-placeholder" v-show="errors.has('curp')">El campo debe tener 18 caracteres</span>
						</div>

						<div class="col-md-3">
							<span class="span-placeholder">RFC</span>
							<input class="vs-input" v-model="rfc" name="rfc" v-validate="'required|min:13|max:13'" v-mask="'AAAA######NNN'" onkeyup="javascript:this.value=this.value.toUpperCase();" />
							<span class="text-danger span-placeholder" v-show="errors.has('rfc')">El campo debe tener 13 caracteres</span>
						</div>


					</div>


					<br /><br /><br />

          <h5>INGRESOS</h5>

          	<div class="row w-full">
						<div class="col-md-3">
                <span class="formGroupExampleInput span-placeholder">¿Cúanto es tu ingreso mensual?</span>
								<div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">$</span>
                  </div>
                  <input type="text" class="vs-input" v-validate="'required|min:3|max:14'"   minlength="2" maxlength="9" name="ingreso.ingresoMensual"  v-model="ingreso.ingresoMensual"  aria-label="Username" aria-describedby="basic-addon1"  @change="formateaIngresoMensual()" v-on:keyup="validaIngersoMensual()" v-on:keypress="isNumber($event)" />
                   <span class="text-danger span-placeholder" v-show="errors.has('ingreso.ingresoMensual')">Campo requerido</span>
                </div>
              </div>

              	<div class="col-md-3">
                    	<span class="span-placeholder">Fecha que empezaste a laborar</span>
                      <input class="vs-input"  v-validate="'required'"  type="date" v-model="ingreso.fechaInicio" name="ingreso.fechaInicio" />
                      <span class="text-danger span-placeholder" v-show="errors.has('ingreso.fechaInicio')">Campo requerido</span>
                </div>

              <div class="col-md-3">
							<div class="form-group">
								<span class="formGroupExampleInput span-placeholder">Tipo de empleo</span>
                <select  id="tiempoEmpleo"  v-validate="'required'"  name="ingreso.tipoEmpleo"  v-model="ingreso.tipoEmpleo" class="vs-input">
                  <option :value="''">Seleccione...</option>
                  <option :value="tipo.Id" v-for="tipo in tiposDeEmpleo" :key="">{{ tipo.Nombre }}</option>
                </select>
                <span class="text-danger span-placeholder" v-show="errors.has('ingreso.tipoEmpleo')">Campo requerido</span>
							</div>
						</div>


             <div class="col-md-3">
							<div class="form-group">
						      	<span class="span-placeholder" for="formasEnganche">Comprueba ingresos con</span>
											<select  id="formasEnganche"  v-validate="'required'"  name="ingreso.comprobanteIngresos" v-model="ingreso.comprobanteIngresos" class="vs-input">
												<option :value="''">Seleccione...</option>
												<option :value="tipo.Id" v-for="tipo in comprobantes" :key="">{{ tipo.Nombre }}</option>
											</select>
                      <span class="text-danger span-placeholder" v-show="errors.has('ingreso.comprobanteIngresos')">Campo requerido</span>
							</div>
						</div>



					</div>



					<div class="row w-full">
						<div class="col-md-9"></div>
						<div class="col-md-3">
							<!-- <button class="btn btn-second" @click="agregaConyuge()" :disabled="!validateForm">Siguiente</button> -->
              <button class="btn btn-second" @click="actualizarInfo()" :disabled="!validateForm">Siguiente</button>
						</div>
					</div>
				</vx-card>
			</div>
		</div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components:{
    'v-select': vSelect,
  },
  data(){
		return{
      maskRfc: ['AAAA999999&&&'],
      conyuge:{
        participaCredito:false,
        email:'',
        nombre:'',
        apellidoPaterno:'',
        apellidoMaterno:'',
        curp:'',
        rfc:'',
        esConyugue:true,
        seraCoPropietario:false,
        consolidaIngresos:false,
        parentesco:1,
      },
      coacreditado:{
        email:'',
        email2:'',
        email3:'',//existe coacreditado
        esConyugue:false,
        seraCoPropietario:true,
        consolidaIngresos:true,
        parentesco:'',
        participaCredito:true,
      },
      ingreso: {
				ingresoId: '',
				ingresoNeto: '',
				ingresoMensual: '',
				tipoEmpleo: '',
				empresa: '',
				fuente: '',
				puesto: '',
				giro: '',
				tipoContrato: '',
				impuestos: false,
				fechaInicio: '',
				domicilio: '',
				colonia: '',
				numeroExterior: '',
				numeroInterior: '',
				ciudad: '',
				municipio: '',
				codigoPostal: '',
				telefono: '',
				area: '',
				sector: '',
				profesion: '',
				comprobanteIngresos: '',
				estado: '',
				pais: '',
				jefe: '',
			},
      completeInfo:true,
      ingresos: [],
			tiposDeEmpleo: [],
			giros: [],
			comprobantes: [],
      id:'',
      key:'',
      email:'',
      nombre:'',
      snombre:'',
      apellidoPaterno:'',
      apellidoMaterno:'',
      telefono:'',
      fechaNacimiento:'',
      ingresosSinImpuestos:'',
      IngresosConImpuestos:'',
      estadoCivil:'',
      regimenMatrimonial:'',
      estadosCiviles:[],
      rfc:'',
      curp:'',
      sexo:'',
      calle:'',
      numeroInterior:'',
      numeroExterior:'',
      colonia:'',
      municipio:'',
      ciudad:'',
      nacionalidad:'',
      estado:'',
      codigoPostal:'',
      estados:[],
      states: ['AGUASCALIENTES','BAJA CALIFORNIA NORTE','BAJA CALIFORNIA SUR','CAMPECHE',
      'CHIAPAS','CHIHUAHUA','COAHUILA','COLIMA','CIUDAD DE MEXICO','DURANGO','ESTADO DE MÉXICO',
      'GUANAJUATO','GUERRERO','HIDALGO','JALISCO','MICHOACAN','MORELOS','NAYARIT','NUEVO LEON',
      'OAXACA','PUEBLA','QUERETARO','QUINTANA ROO','SAN LUIS POTOSI','SINALOA','SONORA','TABASCO',
      'TAMAULIPAS','TLAXCALA','VERACRUZ','YUCATAN','ZACATECAS'],
      parentescos:[],
      regimenes:[],
      nacionalidades:[
        { label: 'MEXICANA',  value: 'MEXICANO' },
        { label: 'EXTRANJERA',  value: 'EXTRANJERO' }
      ],
      codigoSeleccionado:'52',
      codigoPaises:[],
      emailCasoNuevo:{'bandera':'', 'Email':'', 'tel': '', 'Id':''},
      bancos:[],
      esquemas:[],
      plazos:[],
      programas:[],
      mensajeInicial:'',
      AppActiveUser : '',
      socData: '',
      IdLiga: '',
      // header: {
      //     "authorization": "Bearer 16JXvQ9CD1eEoVESY3-4d-bElrAAgAfcB0UgYwAB-XyuNugkcBvgntr6n6VjEAFWenS9Exi_jlLv_RBfmf004TXytHP64dNEuSyhlXOBDtO56hW07txWd4evNWfukK5CO1ETRIOk8GWyjjS7daQ8eLHUz3XefoKEHKE0yIy76o8rZZYTGskAn1Zo3oKcPFzfs_fmVlO15cD_Kxx27-1qqDBsfAphhY7N599ly5ppo5L4XHaL8JAv6mTxXVYxrTZPQDmnLqQ9Nwre7Bd4URM5rR3rVMrAVkPzJ8ohQ9-pgvigB-Fra_SMi31c5T-QrSTB"
      //   },
      header : {},
    }
  },
  watch:{
    estadoCivil:function(){
      if (this.estadoCivil == 2 || this.estadoCivil== 3 || this.estadoCivil== 5 || this.estadoCivil== 6) {
        this.regimenMatrimonial=''
        this.conyuge.participaCredito=false
        this.conyuge.email=''
        this.conyuge.nombre=''
        this.conyuge.apellidoPaterno=''
        this.conyuge.apellidoMaterno=''
        this.conyuge.curp=''
        this.conyuge.rfc=''
        this.conyuge.esConyugue=true
        this.conyuge.seraCoPropietario=true
        this.conyuge.consolidaIngresos=true
        this.conyuge.parentesco=1
        this.conyuge.fechaNacimiento=''
        this.conyuge.sexo=''
        this.conyuge.nacionalidad=''
        this.conyuge.paisRecidencia=''
      }
    }
  },
  mounted(){
    if(!this.$route.params.id){      
        this.getApikey()
        this.getEstados()
        this.getEstadosCiviles()
        this.getListaRegimenes()
        this.getListaParentescos()
        this.getListaCodigoPaises() //sms
        this.getListaTipoEmpleos()
        this.getTipoComprobantes()
        this.DameCredenciales()
        //this.completeInfo=JSON.parse(localStorage.getItem('userInfo')).MsjPrecalificacionSoc

    }else{      
        this.id=this.$route.params.id
        this.getApikey()
        this.dameCaso()
        this.getEstados()
        this.getEstadosCiviles()
        this.getListaRegimenes()
        this.getListaParentescos()
        this.getListaCodigoPaises() //sms
        this.getListaTipoEmpleos()
        this.getTipoComprobantes()
        this.DameCredenciales()
       // this.completeInfo=JSON.parse(localStorage.getItem('userInfo')).MsjPrecalificacionSoc

    }


  },
  computed:{
    validate: function () {
      if (this.estadoCivil == 1 || this.estadoCivil==4) {
        return this.conyuge.email!='' && this.conyuge.nombre!='' &&
        this.conyuge.apellidoPaterno!='' && this.conyuge.apellidoMaterno!=''
      }else {
        return true
      }
    },
    validate2: function () {
      return this.coacreditado.email != '' && this.coacreditado.parentesco != ''
    },
     validateForm(){
      return this.email != '' && this.nombre != '' && this.apellidoPaterno != ''  && this.telefono != ''  && this.fechaNacimiento != ''  && this.sexo != ''  && this.curp != ''  && this.rfc != ''  && this.ingreso.ingresoMensual != ''  && this.ingreso.fechaInicio != '' && this.ingreso.tipoEmpleo != '' && this.ingreso.comprobanteIngresos != ''
    },
    
  },
  methods:{
    async getAuthToken() {
		  let objRequestToken = {
				strApiKey: this.key,
				strMetodo: 'DameAuthToken',
			}
			await this.$axios.post('/', objRequestToken,
			{ headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
			.then( response => {
			if (!response.data.error) {
				this.header = { "Authorization" : "Bearer "+ response.data.access_token};
			} else {
				this.$vs.notify({
				title: 'Ocurrio un error al obtener token',
				text: response.data.error_description,
				color: 'danger',
				position: 'top-right',
				})
			}
			}).catch(function (error) {
			self.$vs.notify({
				title: 'Ocurrio un error de sistema',
				text: error,
				color: 'danger',
				position: 'top-right',
			})
			});
		},
    /*solorfc: function(event) {
      var regex = new RegExp("([A-Z,Ñ,&,a-z,ñ]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z,a-z|\d]{3})+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
          event.preventDefault();
          return false;
        }
      /*const regex = /^([A-Z,Ñ,&,a-z,ñ]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z,a-z|\d]{3})$/
      // const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const matches = regex.test(this.datosPersonales.rfc);
      if(matches){
        this.rfcValido=false
      }else{
        this.rfcValido=true
      }*/

   /* },
    alfanumericos: function(event) {
        var regex = new RegExp("^[ña-zÑA-Z0-9 ]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
          event.preventDefault();
          return false;
        }
    },*/
    soloLetras: function(event) {
        var regex = new RegExp("^[áéíóúüña-zÑÁÉÍÓÚA-Z ]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
          event.preventDefault();
          return false;
          }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
     actualizaCodigo(){
      this.codigoPais=this.codigoSeleccionado
      
    },
    getListaCodigoPaises(){
      var objRequestListaPais = {
				strApiKey: this.key,
				strMetodo: 'ListaPaisLada',
			}
			this.$axios.post('/',objRequestListaPais,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
						this.codigoPaises=response.data.objContenido
            //console.log(this.codigoPaises)
					}
				}
			).catch(function (error) {
        this.makeToast(error)
			})
    },
    MostrarMensajeBroker(){

       var objMensajeInicial = {
          strApiKey: this.key,
          strMetodo: 'DameDatosInmobiliario',
          objEjecutivo: {
           email : JSON.parse(localStorage.getItem('userInfo')).EMail,
          
          }
        }
        this.$axios.post('/',objMensajeInicial,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
                //alert(response.data.objContenido.MsjPrecalificacionSoc)
                this.completeInfo=response.data.objContenido.MsjPrecalificacionSoc
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      
    },
    MostrarMensajeInicial(){
      
      if(!this.mensajeInicial){
        this.mensajeInicial = false;
      }
       //SE ENVIA EL VALOR DE mensajeInicial true o false y se guarda en tabla precalificaciones 
        var objMensajeInicial = {
          strApiKey: this.key,
          strMetodo: 'MensajeInicial',
          objCaso: {
           Email : JSON.parse(localStorage.getItem('userInfo')).EMail,
           MensajeInicial: this.mensajeInicial
          }
        }
        this.$axios.post('/',objMensajeInicial,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){

                this.completeInfo=response.data.objContenido
                this.completeInfo=true
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
        
       this.completeInfo=true
      
    },
    dameCoacreditadoRegistrado(){
      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
      	 EMail: this.coacreditado.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.coacreditado.email3=this.coacreditado.email
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraCoacreditado(){
      let self= this
      if (this.coacreditado.email == this.conyuge.email) {
        let objRequestRegistraSolicitante = {
          strApiKey: this.key,
          strMetodo: 'RegistraSolicitante',
          objSolicitante: {
            EMail: this.conyuge.email,
            Nombres: this.conyuge.nombre,
            SegundoNombre: this.conyuge.snombre,
            ApellidoPaterno: this.conyuge.apellidoPaterno,
            ApellidoMaterno: this.conyuge.apellidoMaterno,
            TelefonoCelular: '',
            FechaNacimiento: '',
          }
        }
        this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              this.agregarCoacreditado()
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en RegistraSolicitante',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }

        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      }else if(this.coacreditado.email3!=''){
        this.agregarCoacreditado()
      }else {
        if (this.coacreditado.email2) {
          this.agregarCoacreditado()
        }else {
          let objRequestRegistraSolicitante = {
            strApiKey: this.key,
            strMetodo: 'RegistraSolicitante',
            objSolicitante: {
              EMail: this.coacreditado.email,
              Nombres: '',
              ApellidoPaterno: '',
              ApellidoMaterno: '',
              TelefonoCelular: '',
              FechaNacimiento: '',
            }
          }
          this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
          .then(
            response => {
              if(response.data.intEstatus == 0){
                this.agregarCoacreditado()
              }else {
                this.$vs.notify({
                  title:'Ocurrio un error en RegistraSolicitante',
                  text:response.data.strError,
                  color:'danger',
                  position:'top-right'
                })
              }
            }

          ).catch(function (error) {
            self.$vs.notify({
              title:'Ocurrio un error de sistema',
              text:error,
              color:'danger',
              position:'top-right'
            })
          })
        }
      }
    },
    agregarCoacreditado(){
      let self= this
      var objRequestRegistraCoacreditado = {
        strApiKey: this.key,
        strMetodo: 'RegistraCoacreditado',
        objCaso: {
          Id : this.id,
          EMail: this.coacreditado.email,
          DatosCoacreditado:{
            EsConyuge:this.coacreditado.esConyugue,
            SeraCoPropietario:this.coacreditado.seraCoPropietario,
            ConsolidaIngresos:this.coacreditado.consolidaIngresos,
            Parentesco:this.coacreditado.parentesco,
            ParticipaCredito:this.coacreditado.participaCredito
          }
        }
      }
      this.$axios.post('/',objRequestRegistraCoacreditado,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCoacreditado',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    agregaConyugeAsCoacreditado(){
      let self= this
      var objRequestRegistraCoacreditado = {
        strApiKey: this.key,
        strMetodo: 'RegistraCoacreditado',
        objCaso: {
          Id : this.id,
          EMail: this.conyuge.email,
          DatosCoacreditado:{
            EsConyuge:this.conyuge.esConyugue,
            SeraCoPropietario:this.conyuge.seraCoPropietario,
            ConsolidaIngresos:this.conyuge.consolidaIngresos,
            Parentesco:this.conyuge.parentesco,
            ParticipaCredito:this.conyuge.participaCredito
          }
        }
      }
      this.$axios.post('/',objRequestRegistraCoacreditado,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCoacreditado',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    agregaConyuge(){


     /* this.emailCasoNuevo.bandera= 'Personales'
      this.emailCasoNuevo.Email= this.email
      this.emailCasoNuevo.tel= this.telefono
            
        this.$emit("bandera",  this.emailCasoNuevo)*/

      if (this.conyuge.email != '') {        
        let self= this

         let sexoConyuge
          if(this.conyuge.sexo== 'H'){
            sexoConyuge=1
          }else{
            sexoConyuge=2
          }

       
        let objRequestRegistraSolicitante = {
          strApiKey: this.key,
          strMetodo: 'RegistraSolicitante',
          objSolicitante: {
            EMail: this.conyuge.email,
            Nombres: this.conyuge.nombre,
            SegundoNombre: this.conyuge.snombre,
            ApellidoPaterno: this.conyuge.apellidoPaterno,
            ApellidoMaterno: this.conyuge.apellidoMaterno,
            TelefonoCelular: this.conyuge.telefono,
            FechaNacimiento: this.conyuge.fechaNacimiento,
            Rfc:this.conyuge.rfc,
            Curp:this.conyuge.curp,
            Sexo:sexoConyuge ,
            Nacionalidad: this.conyuge.nacionalidad,
            PaisRecidencia: this.conyuge.paisRecidencia,
            Ingresos:[
              {
                IngresosMensuales:parseFloat(this.ingreso.ingresoMensual.replace(/,/g,"")),
                IngresosNetos:parseFloat(this.ingreso.ingresoMensual.replace(/,/g,"")),
                TipoEmpleo:this.ingreso.tipoEmpleo,
                ComprobanteIngresos : this.ingreso.comprobanteIngresos,
                FechaInicio : this.ingreso.fechaInicio,
              }
            ],



          }
        }
        this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {
            if(response.data.intEstatus == 0){
              if (this.conyuge.participaCredito) {
                this.agregaConyugeAsCoacreditado()
              }
                 
              //this.actualizarInfo()
              this.verificaUsuario()

           
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en RegistraSolicitante',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
      }else {        
        //this.actualizarInfo()
        this.verificaUsuario()
      }
    },
    verificaUsuario(){
      if(this.socData != null){                        
        this.GeneraLiga()        
      }else{
        this.IdLiga = null
        this.actualizarInfo()
      }             
    },
    actualizarInfo(){      
      
      let self= this
      
      let sexo
       if(this.sexo== 'H'){
        sexo=1
      }else{
        sexo=2
      }
            
      this.emailCasoNuevo.bandera= 'Personales'
      this.emailCasoNuevo.Email= this.email
      this.emailCasoNuevo.tel= this.telefono
            
      this.$emit("bandera",  this.emailCasoNuevo)

      let objRequestRegistraSolicitante = {
        strApiKey: this.key,
        strMetodo: 'RegistraSolicitante',
        objSolicitante: {
          EMail: this.email,
          Nombres: this.nombre,
          SegundoNombre: this.snombre,
          ApellidoPaterno: this.apellidoPaterno,
          ApellidoMaterno: this.apellidoMaterno,
          TelefonoCelular: this.telefono,
          Lada: this.codigoSeleccionado, //agregar sms
          FechaNacimiento: this.fechaNacimiento,
          Rfc: this.rfc,
          Curp: this.curp,
      		Sexo:sexo,
          AccreditedIdV3: this.IdLiga,
           Ingresos:[
              {
                IngresosMensuales:parseFloat(this.ingreso.ingresoMensual.replace(/,/g,"")),
                IngresosNetos:parseFloat(this.ingreso.ingresoMensual.replace(/,/g,"")),
                TipoEmpleo:this.ingreso.tipoEmpleo,
                ComprobanteIngresos : this.ingreso.comprobanteIngresos,
                FechaInicio : this.ingreso.fechaInicio,
              }
            ],
      		
        }
      }
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$axios.post('/',objRequestRegistraSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
          .then(
            response => {
              if(response.data.intEstatus == 0){
                  if(!this.$route.params.id){
                     // this.RegistraNuevoCaso()
                     this.emailCasoNuevo.bandera= 'Personales'
                      this.emailCasoNuevo.Email= this.email
                      this.emailCasoNuevo.tel= this.telefono
                      this.emailCasoNuevo.Id= this.id

                    //  alert('TELEFONO'+this.emailCasoNuevo.Id)
                      
                      this.$emit("bandera",  this.emailCasoNuevo)
                  }

                this.$vs.notify({
                  title:`Datos guardados exitosamente`,
                  position:'top-right'
                })
              }else {
                this.$vs.notify({
                  title:'Ocurrio un error en RegistraSolicitante',
                  text:response.data.strError,
                  color:'danger',
                  position:'top-right'
                })
              }
            }

          ).catch(function (error) {
            self.$vs.notify({
              title:'Ocurrio un error de sistema',
              text:error,
              color:'danger',
              position:'top-right'
            })
          })
        } else {
          this.$vs.notify({
            title:'Ocurrio un error',
            text:'Datos incompletos',
            color:'danger',
            position:'top-right'
          })
        }
      })
    },
    async GeneraLiga(){    
      await this.getAuthToken();      

      let sexo
       if(this.sexo== 'H'){
        sexo='Masculino'
      }else{
        sexo='Femenino'
      }
  
      var mostrar = ""
      let objRequestCotizaCaso = {
        AccreditedModel: {
          Name: this.nombre,
          SName: this.snombre,
          LastName: this.apellidoPaterno,
          SecondLastName: this.apellidoMaterno,
          PersonalMail: this.email,
          CellPhone: this.telefono,
          CURP: this.curp,
          RFC: this.rfc,  
          CivilStatus: null,
          Nationality: null,
          Gender: sexo,
          Age: null,
          StudyGrade: null,
          EconomicDependents: null,
          NumberOfChildren: null,
          ChildrensAge: null,
          CP: null,
          MonetaryIncome: null,
          NumberOfCars: null,
          Activity: null,
          PhoneHome: null,
          DateBirtday: this.fechaNacimiento,
          Cocredited: null,
          Weight: null,
          Height: null,
          NSS: null,
          TypeHousing: null,
          ResidenceTimeHome: null,
          ResidenceTimeTown: null,
          CompanyName: null,
          CompanyAddress: null,
          Position: null,
          TypeCompany: null,
          WorkingTime: null,
        },
        ReferencesModel: {
          Name: null,
          SName: null,
          LastName: null,
          SecondLastName: null,
          Phone: null,
          Relationship: null,
          TimeRelationship: null
        },
        CreditRequestModel: {
          TypeCredit: null,
          HomeValue: null,
          CreditAmount: null,
          Plazo: null
        },
        BrokerEmployeeModel: {
          Id: this.SocGuid,
          BrokerId: this.BrokerId,
          EmployeeName: null,
          EmployeeEmail: null,
          Origin: "Cotizador soc"
        },
        IsQuickQuote:true,
      }

      this.$axios.post('https://apivalidacionriesgos.sisec.mx/api/External/CreateAccredited',objRequestCotizaCaso,{headers : this.header})
        .then(
          response => {
                      
            this.IdLiga = response.data.AccreditedId            
            //this.EnviaCorreo()
            this.actualizarInfo()
            
          }
        ).catch(function (error) {
          if(error.response.data.Message == "The CellPhone already exists"){
            mostrar = "El telefono ya se encuentra registrado intente con otro"
          }else if(error.response.data.Message == "The email already exists"){
            mostrar = "El correo elecronico ya se encuentra registrado intente con otro"
          }else{
            mostrar = error
          }
          
          self.$vs.notify({
            title:'Ocurrio un error en SISEC',
            text:mostrar,
            color:'danger',
            position:'top-right'
          })
        })
    },
    EnviaCorreo(){
      let self=this
      let objRequestEnviaLigaCorreo = {
        Nombre: this.nombre,
        Id: this.IdLiga,
        Correo: this.email
      }

      this.$vs.notify({
        title:'Enviando Correo',
        position:'top-right'
      })

      this.$axios.post('https://cotizador.socasesores.com/admin/formatos/emailComparaleProd/EmailLigaCliente.php',objRequestEnviaLigaCorreo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
        .then(
          response => {

            this.$vs.notify({
              title:'Se envió correctamente el correo electrónico a',
              text:this.email,
              position:'top-right'
            })

          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
    },
    RegistraNuevoCaso(){
      let self=this
      this.bancos=[2,4,6,10,11]
      this.esquemas=[2]
      this.plazos=[20]
      this.programas=[1]
      

      let objRequestRegistraCaso1= {}
        objRequestRegistraCaso1 = {
          strApiKey: this.key,
          strMetodo: 'RegistraCaso',
          objCaso: {
            Id: parseInt(this.id),
            ValorInmueble : parseFloat(1000000),
            MontoSolicitado : parseFloat(800000),
            Plazos : this.plazos,
            Solicitante: this.email,
            Destino : 5,
            Esquemas:this.esquemas,
            Bancos:this.bancos,
            Programas:this.programas,
            PerfilCliente : 2,
            VersionOrigen: 'Web Reporte Credito'
          }
        
      }
      this.$axios.post('/',objRequestRegistraCaso1,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.id=response.data.objContenido.Id

            this.emailCasoNuevo.bandera= 'Personales'
            this.emailCasoNuevo.Email= this.email
            this.emailCasoNuevo.tel= this.telefono
            this.emailCasoNuevo.Id= this.id

          //  alert('TELEFONO'+this.emailCasoNuevo.Id)
            
            this.$emit("bandera",  this.emailCasoNuevo)

            this.dameCaso()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
            
          }
        }

      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.email= response.data.objContenido.Solicitante
            let coacreditado=response.data.objContenido.Coacreditado
            if (coacreditado.length) {
              if (coacreditado[0].EsConyuge && coacreditado[0].ParticipaCredito) {
                this.conyuge.esConyugue=coacreditado[0].EsConyuge
                this.conyuge.email=coacreditado[0].EMail
                this.conyuge.seraCoPropietario=coacreditado[0].SeraCoPropietario
                this.conyuge.consolidaIngresos=coacreditado[0].ConsolidaIngresos
                this.conyuge.parentesco=coacreditado[0].Parentesco
                this.conyuge.participaCredito=coacreditado[0].ParticipaCredito
              }else {
                this.coacreditado.esConyugue=coacreditado[0].EsConyuge
                this.coacreditado.email2=coacreditado[0].EMail
                this.coacreditado.email=coacreditado[0].EMail
                this.coacreditado.seraCoPropietario=coacreditado[0].SeraCoPropietario
                this.coacreditado.consolidaIngresos=coacreditado[0].ConsolidaIngresos
                this.coacreditado.parentesco=coacreditado[0].Parentesco
                this.coacreditado.participaCredito=coacreditado[0].ParticipaCredito
              }
            }
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso 2',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameConyuge(email){
      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
      	 EMail: email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let conyuge =response.data.objContenido
            this.conyuge.nombre= conyuge.Nombres
            this.conyuge.email= conyuge.EMail
            this.conyuge.apellidoPaterno= conyuge.ApellidoPaterno
            this.conyuge.apellidoMaterno= conyuge.ApellidoMaterno
            this.conyuge.rfc=conyuge.Rfc
            this.conyuge.curp = conyuge.Curp
            this.conyuge.fechaNacimiento= conyuge.FechaNacimiento
            if(conyuge.Sexo== 1){
              this.conyuge.sexo='H'
            }else if (conyuge.Sexo== 2) {
              this.conyuge.sexo='M'
            }
            this.conyuge.nacionalidad= conyuge.Nacionalidad
            this.conyuge.paisRecidencia= conyuge.PaisRecidencia

          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self= this
      var objRequestDameSolicitante = {
        strFolioCaso: this.id,
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
      	 EMail: this.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let solicitante =response.data.objContenido
            this.nombre= solicitante.Nombres
            this.snombre= solicitante.SegundoNombre
            this.apellidoPaterno= solicitante.ApellidoPaterno
            this.apellidoMaterno= solicitante.ApellidoMaterno
            this.telefono= solicitante.TelefonoCelular.replace(/\s+/g, '').replace(/-/g, '')
            this.fechaNacimiento= solicitante.FechaNacimiento
            this.rfc=solicitante.Rfc
            if(solicitante.Sexo== 1){
              this.sexo='H'
            }else if (solicitante.Sexo== 2) {
              this.sexo='M'
            }
            this.calle = solicitante.Calle
            this.numeroExterior = solicitante.NumeroExterior
            this.numeroInterior = solicitante.NumeroInterior
            this.colonia = solicitante.Colonia
            this.ciudad = solicitante.Ciudad
            this.municipio = solicitante.Municipio
            this.curp = solicitante.Curp
            this.codigoPostal = solicitante.CodigoPostal
            this.estado = solicitante.Estado
            this.estadoCivil=solicitante.EstadoCivil
            this.regimenMatrimonial=solicitante.RegimenMatrimonial
            this.nacionalidad=solicitante.Nacionalidad
           
            if (solicitante.Conyuge != null && (this.estadoCivil== 1 || this.estadoCivil== 4)) {
              this.dameConyuge(solicitante.Conyuge)
            }
            this.codigoSeleccionado='52'  //solicitante.Lada

            if(solicitante.Ingresos[0]){
                this.ingreso.ingresoMensual= solicitante.Ingresos[0].IngresosMensuales
                this.ingreso.fechaInicio= solicitante.Ingresos[0].FechaInicio
                this.ingreso.tipoEmpleo= solicitante.Ingresos[0].TipoEmpleoId
                this.ingreso.comprobanteIngresos= solicitante.Ingresos[0].ComprobanteIngresosId

            }


           

            
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
        this.socData = JSON.parse(localStorage.getItem('socData'))
        this.MostrarMensajeBroker()
      }
    },
    getEstadosCiviles(){
      let self= this
      var objRequestListaEstadosCiviles = {
        strApiKey: this.key,
        strMetodo: 'ListaEstadosCiviles',
      }
      this.$axios.post('/',objRequestListaEstadosCiviles,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estadosCiviles=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstadosCiviles',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getEstados(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaEstados',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.estados=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstados',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getStateName(id){
      return this.states[id-1]
    },
    getStateNumber(stateName){
      for (var i = 0; i < this.states.length; i++) {
        if(stateName == this.states[i]){
          return i+1
        }
      }
    },
    getListaParentescos(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaParentescos',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.parentescos=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaParentescos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaRegimenes(){
      let self= this
      var objRequestListaCasoEstatus = {
        strApiKey: this.key,
        strMetodo: 'ListaRegimenesMatrimoniales',
      }
      this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.regimenes=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaRegimenesMatrimoniales',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    	getListaTipoEmpleos() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaTipoEmpleos',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.tiposDeEmpleo = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaTipoEmpleos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
    formateaIngresoMensual() {
			if (this.ingreso.ingresoMensual == '') {
				this.ingreso.ingresoMensual = 0
			}
			this.ingreso.ingresoMensual = parseFloat(this.ingreso.ingresoMensual).toFixed(2)
			const exp = /(\d)(?=(\d{3})+(?!\d))/g
			const rep = '$1,'
			this.ingreso.ingresoMensual = this.ingreso.ingresoMensual.toString().replace(exp, rep)
		},
    validaIngersoMensual() {
			this.ingreso.ingresoMensual = this.ingreso.ingresoMensual.replace(/,/g, '')
			const regex = /^(\d{1,9})(\.\d{0,2})?$/
			const matches = regex.test(this.ingreso.ingresoMensual)
			if (matches === true) {
				this.ingreso.ingresoMensual = this.ingreso.ingresoMensual
			} else {
				this.ingreso.ingresoMensual = this.ingreso.ingresoMensual.replace(/.$/, '')
			}
		},
    getTipoComprobantes() {
			let self = this
			var objRequestListaTipoIdentificaciones = {
				strApiKey: this.key,
				strMetodo: 'ListaComprobantesIngreso',
			}
			this.$axios
				.post('/', objRequestListaTipoIdentificaciones, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.comprobantes = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaTipoIdentificaciones',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    DameCredenciales(){
      let self=this
      let objRequestDameCredenciales = {
        strApiKey: this.key,
        strMetodo: 'DameCredenciales',
        objBroker: {
          ApiKey: this.key
        }
      }
      this.$axios.post('/',objRequestDameCredenciales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          this.SocGuid = response.data.objContenido.SocGuid
          this.BrokerId = response.data.objContenido.BrokerId

          //this.SocGuid = 'f5b58eb5-4f2e-49c1-ae90-0fcab572c879';
          //this.BrokerId = 'f5b58eb5-4f2e-49c1-ae90-0fcab572c879';
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
  }
}
</script>

<style lang="css">
.vs-dialog-primary .vs-dialog .vs-dialog-header{
  background: #079DEF;
  color: #FFF !important;
      border-radius: 5px 5px 0px 0px;
}
.con-vs-dialog .vs-dialog header .dialog-title,
.con-vs-dialog .vs-dialog header .vs-dialog-cancel{
  color: #FFF;
}
[dir] .con-vs-dialog .vs-dialog .vs-dialog-text {
  background-color: #f0f0f0;
}
.vs-component.con-vs-checkbox.vs-checkbox-primary.vs-checkbox-default{
  display: flex !important;
    justify-content: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
.red{
  color: red;
}
.input-group{
flex-wrap: nowrap;
}
.input-group-text{
  margin-top: 10px;
  height: 40px;
}
</style>