<template lang="html">
  <div class="">
    <div class="row w-full">
      <div class="col-md-4">
        <span class="span-placeholder">Valor de vivienda</span>
        <div class="inputwrapper" data-required="MXM">
        <input  id="demoTextBox"  class="inputDataText" placeholder="$X,XXX,XXX.XX" v-validate="'max:11', 'min:6'"  v-model="valores.valor" name="vv" @change="updateValores(true); formatea(); calculaPrcentajeCredito()" v-on:keyup="validavalor()" v-on:keypress="isNumber($event)"/>
        <span class="text-danger span-placeholder" v-show="errors.has('vv')">Mínimo son 6 digitos</span>
         </div>
        <span class="text-danger span-placeholder" v-if="this.valores.MontoValorVivienda==false">El valor del inmueble no puede ser menor a 250,000</span>
       
      </div>
      <div class="col-md-4">
        <span class="span-placeholder">Monto de crédito</span>
        <div class="alinear" >
        <input id="demoTextBox"  class="inputDataText" placeholder="$X,XXX,XXX.XX" v-validate="'max:11'" label="Monto de crédito" v-model="valores.credito" name="credito" @change="updateValoresCre(true); formateaCredito(); calculaPrcentajeCredito() " v-on:keyup="validaCredito()" v-on:keypress="isNumber($event)"/>
        <div class="porcentaje">{{valores.porcentajeCredito}}</div>
        <!--<span class="text-danger span-placeholder" v-show="errors.has('credito')">Máximo son 10 digitos</span>-->
        </div>          
        <span class="text-danger span-placeholder" v-show="validacion">Debe ser máximo el 95% del valor de la propiedad. </span>
        <span class="text-danger span-placeholder" v-if="this.valores.MontoValorCredito==false">  *Tu monto de crédito máximo, basado en tus ingresos es de ${{numberFormat(this.IngresoSolN*40)}}</span>
        <span class="text-danger span-placeholder" v-if="this.valores.MontoValorCredito2==false && this.destino!=3">  *Tu monto de crédito máximo, basado en el aforo de 90%, es de ${{numberFormat(this.placValor*0.9)}}</span>
        <span class="text-danger span-placeholder" v-if="this.valores.MontoValorCredito2==false && this.destino==3">  *Tu monto de crédito máximo, basado en el aforo de 70%, es de ${{numberFormat(this.placValor*0.7)}}</span>
        <span class="text-danger span-placeholder" v-if="this.valores.MontoValorCredito3==false">  *El monto de crédito no puede ser menor a 225,000</span>



      </div>
    </div>


    <div class="row w-full">
      <div class="col-md-4">
        <span class="span-placeholder">Plazos a cotizar</span>
        <v-select v-model="valores.plazosACotizar" multiple :closeOnSelect="false" :options="plazos" :dir="$vs.rtl ? 'rtl' : 'ltr'" @input="updateValores(), buscaBancos()"  />
      </div>

      <div class="col-md-4">
        <span class="span-placeholder">Esquemas de pago</span>
        <v-select v-model="valores.esquemasACotizar" multiple :closeOnSelect="false" :options="esquemas" :dir="$vs.rtl ? 'rtl' : 'ltr'" @input="updateValores(), buscaBancos()"/>
      </div>
    </div>
    <input class="vs-input" hidden   v-model="otro" name="otro" @change="updateValores()" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect,
  },
  props: {
    otro: {
      type: Number,
      required: false
    },
    IngresoSolN: {
      type: Number,
      required: false
    },
    destino: {
      type: Number,
      required: false
    },
  },
  data(){
		return{
      valorInmuebleDinamico:'',
      plazos:[
        { label: '20 Años',  value: '20' },
        { label: '15 Años',  value: '15' },
        { label: '10 Años',  value: '10' },
        { label: '5 Años',  value: '5' },
        { label: '23 Años',  value: '23' },
        { label: '19 Años',  value: '19' },
        { label: '18 Años',  value: '18' },
        { label: '17 Años',  value: '17' },
        { label: '16 Años',  value: '16' },
        { label: '14 Años',  value: '14' },
        { label: '13 Años',  value: '13' },
        { label: '12 Años',  value: '12' },
        { label: '11 Años',  value: '11' },
        { label: '9 Años',  value: '9' },
        { label: '8 Años',  value: '8' },
        { label: '7 Años',  value: '7' },
        { label: '6 Años',  value: '6' },
        { label: '4 Años',  value: '4' },
        { label: '3 Años',  value: '3' },
        { label: '2 Años',  value: '2' },
        { label: '1 Años',  value: '1' },
      ],
      esquemas:[
        { label: 'Pago Fijo',  value: '2' },
        { label: 'Pago creciente',  value: '1' },
      ],
      programas:[],
      id:'',
      valores:{
        destino:1,
        valor:'',
        credito:'',
        esquemasACotizar:[{ label: 'Pago Fijo',  value: '2' }],
        plazosACotizar:[{ label: '20 Años',  value: '20' }],
        bancos:[],
        gastosNotarial:6,
          otro:0,
        porcentajeCredito:70.00,
      MontoValorVivienda:true,
      MontoValorCredito:true,
      MontoValorCredito2:true,
      MontoValorCredito3:true,
      },
      IngresoSol:0,
      gastosNotariales:[
        {label : '1 %', value : '1'},
        {label : '2 %', value : '2'},
        {label : '3 %', value : '3'},
        {label : '4 %', value : '4'},
        {label : '5 %', value : '5'},
        {label : '6 %', value : '6'},
        {label : '7 %', value : '7'},
        {label : '8 %', value : '8'},
        {label : '9 %', value : '9'},
        {label : '10 %', value : '10'}
      ],

      strIngresosDe:0,
      ProgramaDefaul:false,
      placValor:0
    }
  },
  mounted(){
    this.id=this.$route.params.id

    this.getApikey()
    if(this.id){
      this.dameCaso()

    }
    if(this.$route.path == '/nuevaCotizacion' && localStorage.getItem('caso')){
      this.id=localStorage.getItem('caso')
      this.dameCaso()
    }
   
    if(this.IngresoSolN==0){
        this.valores.valor= '1,000,000'
        this.valores.credito='700,00'
       
    }else{
      

      if(this.destino==3){
         this.strIngresosDe= (this.IngresoSolN*40) / 0.7
        this.valores.valor= (this.IngresoSolN*40) / 0.7
        
        if(this.strIngresosDe < 250000){
          this.valores.MontoValorVivienda==false
        }
        this.formatea(this.valores.valor)


        this.valores.credito= this.IngresoSolN*40

         if(this.valores.credito > (this.IngresoSolN*40)){
           this.MontoValorCredito==false
         }

          if(this.valores.credito > (this.strIngresosDe*0.7)){
           this.valores.MontoValorCredito2==false
         }

          if(this.valores.credito < 225000){
           this.valores.MontoValorCredito3==false
         }

        this.formateaCredito(this.valores.credito)

      }else{
 ///comienza adquisicion
 this.strIngresosDe= (this.IngresoSolN*40) / 0.9
        this.valores.valor= (this.IngresoSolN*40) / 0.9
        
        if(this.strIngresosDe < 250000){
          this.valores.MontoValorVivienda==false
        }
        this.formatea(this.valores.valor)


        this.valores.credito= this.IngresoSolN*40

         if(this.valores.credito > (this.IngresoSolN*40)){
           this.MontoValorCredito==false
         }

          if(this.valores.credito > (this.strIngresosDe*0.9)){
           this.valores.MontoValorCredito2==false
         }

          if(this.valores.credito < 225000){
           this.valores.MontoValorCredito3==false
         }

        this.formateaCredito(this.valores.credito)
      }
       

       

    }
     this.updateValores()
    
  },
  watch: {
    /*valor: function(newValue) {
      this.valores.valor= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    credito: function(newValue) {
      this.valores.credito= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },*/
    otro:function(){
      if(this.otro > 0){
        this.updateValores()
      }
    },
    IngresoSolN:function(){
      if(this.IngresoSolN > 0){
        this.updateValores()
      }
    }

  },
  computed:{
    validacion() {
      return this.valores.credito.toString().replace(/,/g,"") > this.valores.valor.toString().replace(/,/g,"") * 0.95
    },
    valor(){
      return this.valores.valor
    },
    credito(){
      return this.valores.credito
    }
  },
  methods:{
    	numberFormat(number) {
			return number
				.toFixed(3)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		},
    calculaPrcentajeCredito(){
      this.valores.porcentajeCredito= (this.valores.credito.toString().replace(/,/g,"") / this.valores.valor.toString().replace(/,/g,"")) * 100
      this.valores.porcentajeCredito=this.valores.porcentajeCredito.toFixed(2)+'%'
    },
    validavalor() {
      this.valores.valor=this.valores.valor.replace(/,/g,"")
      const regex = /^(\d{1,9})(\.\d{0,2})?$/  
      const matches = regex.test(this.valores.valor);
      if(matches===true){
      this.valores.valor= this.valores.valor
      }else{
        this.valores.valor=this.valores.valor.replace(/.$/, '')
      }
    },
    formatea(){

      this.valores.valor = this.valores.valor.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

       if(this.valores.valor=='' || this.valores.valor==0){
        this.valores.valor = 100000
      }     
      if(this.valores.valor.length < 6 || this.valores.valor.length > 13){
        this.valores.valor = 100000 
      }

      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.valores.valor= this.valores.valor.toString().replace(exp,rep);
    },
    validaCredito() {
      this.valores.credito=this.valores.credito.replace(/,/g,"")
      const regex = /^(\d{1,9})(\.\d{0,2})?$/  
      const matches = regex.test(this.valores.credito);
      if(matches===true){
      this.valores.credito= this.valores.credito
      }else{
        this.valores.credito=this.valores.credito.replace(/.$/, '')
      }
    },
    formateaCredito(){

      this.valores.credito = this.valores.credito.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

      if(this.valores.credito =='' || this.valores.credito == 0 || this.valores.credito.length > 13){
        this.valores.credito=700000
      }
      

      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.valores.credito= this.valores.credito.toString().replace(exp,rep);
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateValores(e){  
      this.valores.otro=this.otro
     // this.$emit('updateValores',this.valores)
     

       if(this.IngresoSolN==0){
        this.valores.valor= '1,000,000'
        }else{
          if( e != true){

      this.valores.MontoValorVivienda=true
      this.valores.MontoValorCredito0=true
      this.valores.MontoValorCredito2=true
      this.valores.MontoValorCredito3=true

          if(this.destino==3){

            this.strIngresosDe= (this.IngresoSolN*40) / 0.7

            this.valores.valor= (this.IngresoSolN*40) / 0.7
            this.formatea(this.valores.valor)
              
            if(this.strIngresosDe < 250000){
              this.valores.MontoValorVivienda==false
            }

            if((this.IngresoSolN*40)< (this.strIngresosDe * 0.7)){
                this.valores.credito= this.IngresoSolN*40
            }
            else{
              this.valores.credito= this.strIngresosDe * 0.7
            }


            if(this.valores.credito > (this.IngresoSolN*40)){
              this.MontoValorCredito==false
            }

            if(this.valores.credito > (this.strIngresosDe*0.9)){
            this.valores.MontoValorCredito2==false
            }

            if(this.valores.credito < 225000){
            this.valores.MontoValorCredito3==false
            }

            this.formateaCredito(this.valores.credito)


           }else{
            

            this.strIngresosDe= (this.IngresoSolN*40) / 0.9

            this.valores.valor= (this.IngresoSolN*40) / 0.9
            this.formatea(this.valores.valor)
              
            if(this.strIngresosDe < 250000){
              this.valores.MontoValorVivienda==false
            }

            if((this.IngresoSolN*40)< (this.strIngresosDe * 0.9)){
                this.valores.credito= this.IngresoSolN*40
               // this.formateaCredito(this.valores.credito)
            }else{
              this.valores.credito= this.strIngresosDe * 0.9
              // this.formateaCredito(this.valores.credito)
            }


            if(this.valores.credito > (this.IngresoSolN*40)){
              this.MontoValorCredito==false
            }

            if(this.valores.credito > (this.strIngresosDe*0.9)){
            this.valores.MontoValorCredito2==false
            }

            if(this.valores.credito < 225000){
            this.valores.MontoValorCredito3==false
            }
            
            this.formateaCredito(this.valores.credito)


           }
         
          

            
          }else{
            
            if(this.valores.valor < 250000){
               this.valores.MontoValorVivienda=false
               
            }else{
               this.valores.MontoValorVivienda=true
            }

           
            
            if(this.destino==3){

              this.strIngresosDe= (this.IngresoSolN*40)
              

              if((this.IngresoSolN*40) < (this.valores.valor * 0.7)){
                  this.valores.credito= this.IngresoSolN*40
                
              }else{
                this.valores.credito= this.valores.valor  * 0.7
              
              }


              if(this.valores.credito > (this.IngresoSolN*40)){
                this.valores.MontoValorCredito=false
              }else{
                this.valores.MontoValorCredito=true 
              }

              this.placValor=parseFloat(this.valores.valor.replace(/,/g,""))

                if(this.valores.credito > ( this.placValor*0.7)){
                this.valores.MontoValorCredito2=false
                  
              }else{
                this.valores.MontoValorCredito2=true 
              }

                if(this.valores.credito < 225000){
                this.valores.MontoValorCredito3=false
              }else{
                this.valores.MontoValorCredito3=true 
              }

              this.formateaCredito(this.valores.credito)


            }else{
              this.strIngresosDe= (this.IngresoSolN*40)
              

              if((this.IngresoSolN*40) < (this.valores.valor * 0.9)){
                  this.valores.credito= this.IngresoSolN*40
                
              }else{
                this.valores.credito= this.valores.valor  * 0.9
                
              }


               if(this.valores.credito > (this.IngresoSolN*40)){
                  this.valores.MontoValorCredito=false
                }else{
                  this.valores.MontoValorCredito=true 
                }

                this.placValor=parseFloat(this.valores.valor.replace(/,/g,""))

                  if(this.valores.credito > (this.placValor*0.9)){
                  this.valores.MontoValorCredito2=false
                
                }else{
                  this.valores.MontoValorCredito2=true 
                }


               if(this.valores.credito < 225000){
                  this.valores.MontoValorCredito3=false
                }else{
                  this.valores.MontoValorCredito3=true 
                }

              this.formateaCredito(this.valores.credito)

            }



           }
            
        }
        this.$emit('updateValores',this.valores)
        
    },

   updateValoresCre(e){  
      this.valores.otro=this.otro 

      if(this.destino==3){
         if(this.valores.credito > (this.IngresoSolN*40)){
           this.valores.MontoValorCredito=false
         }else{
          this.valores.MontoValorCredito=true 
         }

         this.placValor=parseFloat(this.valores.valor.replace(/,/g,""))

          if(this.valores.credito > ( this.placValor*0.7)){
           this.valores.MontoValorCredito2=false
            
         }else{
          this.valores.MontoValorCredito2=true 
         }

          if(this.valores.credito < 225000){
           this.valores.MontoValorCredito3=false
         }else{
          this.valores.MontoValorCredito3=true 
         }

      } else{

         if(this.valores.credito > (this.IngresoSolN*40)){
           this.valores.MontoValorCredito=false
         }else{
          this.valores.MontoValorCredito=true 
         }

        this.placValor=parseFloat(this.valores.valor.replace(/,/g,""))

          if(this.valores.credito > (this.placValor*0.9)){
           this.valores.MontoValorCredito2=false
        
         }else{
          this.valores.MontoValorCredito2=true 
         }


          if(this.valores.credito < 225000){
           this.valores.MontoValorCredito3=false
         }else{
          this.valores.MontoValorCredito3=true 
         }


      } 

       if(this.valores.credito > (this.IngresoSolN*40)){
           this.valores.MontoValorCredito==false
         }

          if(this.valores.credito > (this.strIngresosDe*0.7)){
           this.valores.MontoValorCredito2==false
         }

          if(this.valores.credito < 225000){
           this.valores.MontoValorCredito3==false
         }

      this.$emit('updateValores',this.valores)
        
    },
    buscaBancos(){
      this.valores.otro=this.otro
      this.$emit('buscaBancos',this.valores)
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let cotizacion=response.data.objContenido;
            // this.valores.destino=cotizacion.Destino
            this.valores.plazosACotizar=[]
            this.valores.esquemasACotizar=[]

          
           
            this.valores.valor= cotizacion.ValorInmueble//(cotizacion.ValorInmueble).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")//.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
           
            this.valores.credito=(cotizacion.MontoSolicitado).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")//.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            for (var i = 0; i < cotizacion.Plazos.length; i++) {
              this.valores.plazosACotizar.push(cotizacion.Plazos[i]+' Años')
            }
            for (var i = 0; i < cotizacion.Esquemas.length; i++) {
              if (cotizacion.Esquemas[i] == 2) {
                this.valores.esquemasACotizar.push({ label: 'Pago Fijo',  value: '2' })
              }  else if (cotizacion.Esquemas[i] == 1) {
                this.valores.esquemasACotizar.push({ label: 'Pago creciente',  value: '1' })
              }
            }
            this.calculaPrcentajeCredito()
            this.updateValores()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
  }
}
</script>

<style lang="css" scoped>
.select-custom{
  margin-top:0.5% !important;
}
</style>
